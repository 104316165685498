import React from 'react'
import { Fade } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import '../styles/css/style.scss';
import CourseMenu from './CourseMenu.js';
import CourseDescription from './CourseDescription.js';
import CourseLessons from './CourseLessons.js';
import MetaDecorator from '../lib/MetaDecorator';
//import {PageView} from '../lib/PageTracking.js';


export default function CourseOverview() {

    const {id} = useParams();
    //console.log(useParams());

    // PageView();

    return (
        <div className="justify-content-center">
            <MetaDecorator title="gencode Course Overview" description="Free computing overview and programming courses for elementary, middle school and high school students." />
            <CourseMenu />
            <Fade>
                <CourseDescription id={id}/>
            </Fade>
            <Fade>
                <CourseLessons id={id}/>
            </Fade>
        </div>
    );
}