/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      userId
      coins {
        items {
          id
          coins
          coinDate
          eventName
          createdAt
          updatedAt
        }
        nextToken
      }
      awards {
        items {
          id
          awardName
          awardDescription
          awardDate
          eventName
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          userId
          eventId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      courses {
        items {
          id
          userId
          courseId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      lessons {
        items {
          id
          userId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userId
      coins {
        items {
          id
          coins
          coinDate
          eventName
          createdAt
          updatedAt
        }
        nextToken
      }
      awards {
        items {
          id
          awardName
          awardDescription
          awardDate
          eventName
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          userId
          eventId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      courses {
        items {
          id
          userId
          courseId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      lessons {
        items {
          id
          userId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userId
      coins {
        items {
          id
          coins
          coinDate
          eventName
          createdAt
          updatedAt
        }
        nextToken
      }
      awards {
        items {
          id
          awardName
          awardDescription
          awardDate
          eventName
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          userId
          eventId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      courses {
        items {
          id
          userId
          courseId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      lessons {
        items {
          id
          userId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCoins = /* GraphQL */ `
  mutation CreateCoins(
    $input: CreateCoinsInput!
    $condition: ModelCoinsConditionInput
  ) {
    createCoins(input: $input, condition: $condition) {
      id
      coins
      coinDate
      eventName
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCoins = /* GraphQL */ `
  mutation UpdateCoins(
    $input: UpdateCoinsInput!
    $condition: ModelCoinsConditionInput
  ) {
    updateCoins(input: $input, condition: $condition) {
      id
      coins
      coinDate
      eventName
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoins = /* GraphQL */ `
  mutation DeleteCoins(
    $input: DeleteCoinsInput!
    $condition: ModelCoinsConditionInput
  ) {
    deleteCoins(input: $input, condition: $condition) {
      id
      coins
      coinDate
      eventName
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAwards = /* GraphQL */ `
  mutation CreateAwards(
    $input: CreateAwardsInput!
    $condition: ModelAwardsConditionInput
  ) {
    createAwards(input: $input, condition: $condition) {
      id
      awardName
      awardDescription
      awardDate
      eventName
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAwards = /* GraphQL */ `
  mutation UpdateAwards(
    $input: UpdateAwardsInput!
    $condition: ModelAwardsConditionInput
  ) {
    updateAwards(input: $input, condition: $condition) {
      id
      awardName
      awardDescription
      awardDate
      eventName
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAwards = /* GraphQL */ `
  mutation DeleteAwards(
    $input: DeleteAwardsInput!
    $condition: ModelAwardsConditionInput
  ) {
    deleteAwards(input: $input, condition: $condition) {
      id
      awardName
      awardDescription
      awardDate
      eventName
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      eventId
      eventName
      eventType
      eventDescription
      eventSpeaker
      eventImageUrl
      eventRegistrationLink
      eventJoinLink
      eventStartTime
      eventEndTime
      eventCoins
      display
      users {
        items {
          id
          userId
          eventId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      eventId
      eventName
      eventType
      eventDescription
      eventSpeaker
      eventImageUrl
      eventRegistrationLink
      eventJoinLink
      eventStartTime
      eventEndTime
      eventCoins
      display
      users {
        items {
          id
          userId
          eventId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      eventId
      eventName
      eventType
      eventDescription
      eventSpeaker
      eventImageUrl
      eventRegistrationLink
      eventJoinLink
      eventStartTime
      eventEndTime
      eventCoins
      display
      users {
        items {
          id
          userId
          eventId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      courseId
      courseName
      courseType
      courseDescription
      courseImageUrl
      courseLink
      courseCoins
      courseStartDate
      display
      users {
        items {
          id
          userId
          courseId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      lessons {
        items {
          id
          courseId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      courseId
      courseName
      courseType
      courseDescription
      courseImageUrl
      courseLink
      courseCoins
      courseStartDate
      display
      users {
        items {
          id
          userId
          courseId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      lessons {
        items {
          id
          courseId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      courseId
      courseName
      courseType
      courseDescription
      courseImageUrl
      courseLink
      courseCoins
      courseStartDate
      display
      users {
        items {
          id
          userId
          courseId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      lessons {
        items {
          id
          courseId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLesson = /* GraphQL */ `
  mutation CreateLesson(
    $input: CreateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    createLesson(input: $input, condition: $condition) {
      id
      lessonId
      lessonName
      lessonType
      lessonDescription
      lessonImageUrl
      lessonVideoUrl
      lessonPresenter
      lessonCoins
      lessonStartDate
      display
      users {
        items {
          id
          userId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      courses {
        items {
          id
          courseId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLesson = /* GraphQL */ `
  mutation UpdateLesson(
    $input: UpdateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    updateLesson(input: $input, condition: $condition) {
      id
      lessonId
      lessonName
      lessonType
      lessonDescription
      lessonImageUrl
      lessonVideoUrl
      lessonPresenter
      lessonCoins
      lessonStartDate
      display
      users {
        items {
          id
          userId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      courses {
        items {
          id
          courseId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLesson = /* GraphQL */ `
  mutation DeleteLesson(
    $input: DeleteLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    deleteLesson(input: $input, condition: $condition) {
      id
      lessonId
      lessonName
      lessonType
      lessonDescription
      lessonImageUrl
      lessonVideoUrl
      lessonPresenter
      lessonCoins
      lessonStartDate
      display
      users {
        items {
          id
          userId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      courses {
        items {
          id
          courseId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserEvents = /* GraphQL */ `
  mutation CreateUserEvents(
    $input: CreateUserEventsInput!
    $condition: ModelUserEventsConditionInput
  ) {
    createUserEvents(input: $input, condition: $condition) {
      id
      userId
      eventId
      status
      statusDate
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      event {
        id
        eventId
        eventName
        eventType
        eventDescription
        eventSpeaker
        eventImageUrl
        eventRegistrationLink
        eventJoinLink
        eventStartTime
        eventEndTime
        eventCoins
        display
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserEvents = /* GraphQL */ `
  mutation UpdateUserEvents(
    $input: UpdateUserEventsInput!
    $condition: ModelUserEventsConditionInput
  ) {
    updateUserEvents(input: $input, condition: $condition) {
      id
      userId
      eventId
      status
      statusDate
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      event {
        id
        eventId
        eventName
        eventType
        eventDescription
        eventSpeaker
        eventImageUrl
        eventRegistrationLink
        eventJoinLink
        eventStartTime
        eventEndTime
        eventCoins
        display
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserEvents = /* GraphQL */ `
  mutation DeleteUserEvents(
    $input: DeleteUserEventsInput!
    $condition: ModelUserEventsConditionInput
  ) {
    deleteUserEvents(input: $input, condition: $condition) {
      id
      userId
      eventId
      status
      statusDate
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      event {
        id
        eventId
        eventName
        eventType
        eventDescription
        eventSpeaker
        eventImageUrl
        eventRegistrationLink
        eventJoinLink
        eventStartTime
        eventEndTime
        eventCoins
        display
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserCourses = /* GraphQL */ `
  mutation CreateUserCourses(
    $input: CreateUserCoursesInput!
    $condition: ModelUserCoursesConditionInput
  ) {
    createUserCourses(input: $input, condition: $condition) {
      id
      userId
      courseId
      status
      statusDate
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      course {
        id
        courseId
        courseName
        courseType
        courseDescription
        courseImageUrl
        courseLink
        courseCoins
        courseStartDate
        display
        users {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserCourses = /* GraphQL */ `
  mutation UpdateUserCourses(
    $input: UpdateUserCoursesInput!
    $condition: ModelUserCoursesConditionInput
  ) {
    updateUserCourses(input: $input, condition: $condition) {
      id
      userId
      courseId
      status
      statusDate
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      course {
        id
        courseId
        courseName
        courseType
        courseDescription
        courseImageUrl
        courseLink
        courseCoins
        courseStartDate
        display
        users {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserCourses = /* GraphQL */ `
  mutation DeleteUserCourses(
    $input: DeleteUserCoursesInput!
    $condition: ModelUserCoursesConditionInput
  ) {
    deleteUserCourses(input: $input, condition: $condition) {
      id
      userId
      courseId
      status
      statusDate
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      course {
        id
        courseId
        courseName
        courseType
        courseDescription
        courseImageUrl
        courseLink
        courseCoins
        courseStartDate
        display
        users {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserLessons = /* GraphQL */ `
  mutation CreateUserLessons(
    $input: CreateUserLessonsInput!
    $condition: ModelUserLessonsConditionInput
  ) {
    createUserLessons(input: $input, condition: $condition) {
      id
      userId
      lessonId
      status
      statusDate
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      lesson {
        id
        lessonId
        lessonName
        lessonType
        lessonDescription
        lessonImageUrl
        lessonVideoUrl
        lessonPresenter
        lessonCoins
        lessonStartDate
        display
        users {
          nextToken
        }
        courses {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserLessons = /* GraphQL */ `
  mutation UpdateUserLessons(
    $input: UpdateUserLessonsInput!
    $condition: ModelUserLessonsConditionInput
  ) {
    updateUserLessons(input: $input, condition: $condition) {
      id
      userId
      lessonId
      status
      statusDate
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      lesson {
        id
        lessonId
        lessonName
        lessonType
        lessonDescription
        lessonImageUrl
        lessonVideoUrl
        lessonPresenter
        lessonCoins
        lessonStartDate
        display
        users {
          nextToken
        }
        courses {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserLessons = /* GraphQL */ `
  mutation DeleteUserLessons(
    $input: DeleteUserLessonsInput!
    $condition: ModelUserLessonsConditionInput
  ) {
    deleteUserLessons(input: $input, condition: $condition) {
      id
      userId
      lessonId
      status
      statusDate
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      lesson {
        id
        lessonId
        lessonName
        lessonType
        lessonDescription
        lessonImageUrl
        lessonVideoUrl
        lessonPresenter
        lessonCoins
        lessonStartDate
        display
        users {
          nextToken
        }
        courses {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCourseLessons = /* GraphQL */ `
  mutation CreateCourseLessons(
    $input: CreateCourseLessonsInput!
    $condition: ModelCourseLessonsConditionInput
  ) {
    createCourseLessons(input: $input, condition: $condition) {
      id
      courseId
      lessonId
      status
      statusDate
      course {
        id
        courseId
        courseName
        courseType
        courseDescription
        courseImageUrl
        courseLink
        courseCoins
        courseStartDate
        display
        users {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      lesson {
        id
        lessonId
        lessonName
        lessonType
        lessonDescription
        lessonImageUrl
        lessonVideoUrl
        lessonPresenter
        lessonCoins
        lessonStartDate
        display
        users {
          nextToken
        }
        courses {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCourseLessons = /* GraphQL */ `
  mutation UpdateCourseLessons(
    $input: UpdateCourseLessonsInput!
    $condition: ModelCourseLessonsConditionInput
  ) {
    updateCourseLessons(input: $input, condition: $condition) {
      id
      courseId
      lessonId
      status
      statusDate
      course {
        id
        courseId
        courseName
        courseType
        courseDescription
        courseImageUrl
        courseLink
        courseCoins
        courseStartDate
        display
        users {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      lesson {
        id
        lessonId
        lessonName
        lessonType
        lessonDescription
        lessonImageUrl
        lessonVideoUrl
        lessonPresenter
        lessonCoins
        lessonStartDate
        display
        users {
          nextToken
        }
        courses {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourseLessons = /* GraphQL */ `
  mutation DeleteCourseLessons(
    $input: DeleteCourseLessonsInput!
    $condition: ModelCourseLessonsConditionInput
  ) {
    deleteCourseLessons(input: $input, condition: $condition) {
      id
      courseId
      lessonId
      status
      statusDate
      course {
        id
        courseId
        courseName
        courseType
        courseDescription
        courseImageUrl
        courseLink
        courseCoins
        courseStartDate
        display
        users {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      lesson {
        id
        lessonId
        lessonName
        lessonType
        lessonDescription
        lessonImageUrl
        lessonVideoUrl
        lessonPresenter
        lessonCoins
        lessonStartDate
        display
        users {
          nextToken
        }
        courses {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createContactUs = /* GraphQL */ `
  mutation CreateContactUs(
    $input: CreateContactUsInput!
    $condition: ModelContactUsConditionInput
  ) {
    createContactUs(input: $input, condition: $condition) {
      id
      userId
      name
      email
      message
      note
      createdAt
      updatedAt
    }
  }
`;
export const updateContactUs = /* GraphQL */ `
  mutation UpdateContactUs(
    $input: UpdateContactUsInput!
    $condition: ModelContactUsConditionInput
  ) {
    updateContactUs(input: $input, condition: $condition) {
      id
      userId
      name
      email
      message
      note
      createdAt
      updatedAt
    }
  }
`;
export const deleteContactUs = /* GraphQL */ `
  mutation DeleteContactUs(
    $input: DeleteContactUsInput!
    $condition: ModelContactUsConditionInput
  ) {
    deleteContactUs(input: $input, condition: $condition) {
      id
      userId
      name
      email
      message
      note
      createdAt
      updatedAt
    }
  }
`;
export const createUserAnalytics = /* GraphQL */ `
  mutation CreateUserAnalytics(
    $input: CreateUserAnalyticsInput!
    $condition: ModelUserAnalyticsConditionInput
  ) {
    createUserAnalytics(input: $input, condition: $condition) {
      id
      userId
      page
      date
      deviceType
      pageParamName
      pageParamValue
      createdAt
      updatedAt
    }
  }
`;
export const updateUserAnalytics = /* GraphQL */ `
  mutation UpdateUserAnalytics(
    $input: UpdateUserAnalyticsInput!
    $condition: ModelUserAnalyticsConditionInput
  ) {
    updateUserAnalytics(input: $input, condition: $condition) {
      id
      userId
      page
      date
      deviceType
      pageParamName
      pageParamValue
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserAnalytics = /* GraphQL */ `
  mutation DeleteUserAnalytics(
    $input: DeleteUserAnalyticsInput!
    $condition: ModelUserAnalyticsConditionInput
  ) {
    deleteUserAnalytics(input: $input, condition: $condition) {
      id
      userId
      page
      date
      deviceType
      pageParamName
      pageParamValue
      createdAt
      updatedAt
    }
  }
`;
