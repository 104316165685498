import React, {Component} from 'react';
import {Container, Row, Col, Form} from 'react-bootstrap';
import {API,graphqlOperation} from '@aws-amplify/api';
import { createContactUs } from '../graphql/mutations';
import AWS from 'aws-sdk'
//import '../styles/css/style.css';

export default class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
           userId: '', 
           name: '',
           email: '',
           message: '', 
           errorMessage: '',
           successMessage: '',
           setIsLoading: false,
        };

    }

    componentDidMount() {
      }

    
    /**
     * 
     * @param {*} event 
     */  
    saveContactUsSubmission = async(event) => {

        event.preventDefault();

        //const username = this.state.username;
        //const authCode = event.target.elements.formAuthCode.value;
        //console.log(this.state.name);
        //console.log(this.state.email);
        //console.log(this.state.message);

        var isDataValid = true;
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


        if (this.state.name.length === 0) {
            this.setState({
                errorMessage: "Please enter your name, email and message.",
            })
            isDataValid = false;
        }
        else if (this.state.email.length === 0) {
            this.setState({
                errorMessage: "Please enter your name, email and message.",
            })
            isDataValid = false;
        }
        else if (!emailRegex.test(this.state.email)) {
            this.setState({
                errorMessage: "Please enter your name, email and message.",
            })
            isDataValid = false;
        }
        else if (!this.state.message.length === 0) {
            this.setState({
                errorMessage: "Please enter your name, email and message.",
            })
            isDataValid = false;
        }

        if (!isDataValid) {
            this.setState({
                successMessage: '',
            })
            return;
        }

        this.setState({
            setIsLoading: true,
            errorMessage: '',
        });

        try {
            // await Auth.currentAuthenticatedUser().then((user) => {
            //     this.setState({
            //         userId: user.attributes.email,   
            //     });
            // });
            //console.log(this.state.userId);

            // Create GraphQL User Profile
            // const gqContactUs = {
            //     userId: '',
            //     name: this.state.name,
            //     email: this.state.email,
            //     message: this.state.message,
            // };

            // await API.graphql(graphqlOperation(createContactUs, {input: gqContactUs})).then((response) => {
            //     console.log(response.data.createContactUs)                   
            // });

            const payload = {
                name: this.state.name,
                email: this.state.email,
                message: this.state.message,
            };
            //console.log(payload);
        
            this.sendEmail(payload);


            this.setState({
                setIsLoading: false,
            })
        }
        catch(error) {
            console.log("Error sending message", error);
            this.setState({
                errorMessage: "Error sending message. Please try again",
                setIsLoading: false,
            });
            return;
        }
        this.setState({
            name: '',
            email: '',
            message: '',
            successMessage: 'Your message sent successfully'
        });
        //window.location.reload(false);
    };

    /**
     * Send Email
     */
    sendEmail = async (data) => {

        var message = "Name: " + data.name + "<BR/>";
        message = message + "Email: " + data.email + "<BR/>";
        message = message + "Message: <BR/>" + data.message + "<BR/>";

        const params = {
            Destination: {
                ToAddresses: [
                    "gencode.us@gmail.com"
                 ]
            },
            Message: {
                Body: {
                    Html: {
                    Charset: "UTF-8", 
                    Data: message,
                    }, 
                }, 
                Subject: {
                 Charset: "UTF-8", 
                 Data: "Contact Us Inquiry from " + data.name,
               },
            },
            ReplyToAddresses: [
                "gencode.us@gmail.com",
            ], 
            ReturnPath: "gencode.us@gmail.com", 
            Source: "gencode.us@gmail.com", 

        };
        //console.log(params);

        var AWS = require('aws-sdk');
        AWS.config.update({
            region: 'us-east-2',
            credentials: {
                accessKeyId: "AKIA32CYNC34WRWKGZ7R",
                secretAccessKey: "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",
            }
        });


        var ses = new AWS.SES();

        ses.sendEmail(params, function(err, data) {
            if (err) console.log(err, err.stack); // an error occurred
            else     console.log("Email sent");           // successful response
          });
    }


    render() {

        return(

            <div id="contact">
                <Container>
                    <Row>
                        <Col md={8}>
                        </Col>
                    </Row>
                    <Row className="section-title">
                        <Col md={8}>
                            <h2>Get In Touch</h2>
                            <p>Contact us to learn more about the gencode platform and how you can help advance the mission.</p>
                        </Col>
                    </Row>
                    <Row className="section-title">
                        <Col md={8}>
                            <Form name="sentMessage" id="contactForm"> 

                                <Row>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <Form.Group controlId="name">
                                                <Form.Control
                                                    className="login-input"
                                                    placeholder="Name"
                                                    defaultValue=""
                                                    onChange={e => {
                                                            this.setState({
                                                                name: e.target.value,
                                                                errorMessage: '',
                                                            })
                                                        }
                                                    }
                                                />
                                            </Form.Group>
                
                                            <p className="help-block text-danger"></p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <Form.Group controlId="name">
                                                <Form.Control
                                                    className="login-input"
                                                    placeholder="Email"
                                                    defaultValue=""
                                                    onChange={e => {
                                                            this.setState({
                                                                email: e.target.value,
                                                                errorMessage: '',
                                                            })
                                                        }
                                                    }
                                                />
                                            </Form.Group>
                                            <p className="help-block text-danger"></p>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="form-group">
                                    <Form.Group controlId="message">
                                        <Form.Control 
                                            as="textarea" 
                                            rows={3}
                                            className="login-input"
                                            placeholder="Message"
                                            defaultValue=""
                                            onChange={e => {
                                                    this.setState({
                                                        message: e.target.value,
                                                        errorMessage: '',
                                                    })
                                                }
                                            }
                                        >

                                        </Form.Control>
                                    </Form.Group>
                                   
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div id="success" className="error-text">{this.state.errorMessage}</div>
                                <div id="error"><p>{this.state.successMessage}</p></div>
                                <button type="submit" className="btn btn-custom btn-lg" onClick={(event) => this.saveContactUsSubmission(event)}>Send Message</button>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="social">
                                <ul>
                                    <li><a href="https://www.facebook.com/gencode.us"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com/gencode.us/"><i className="fa fa-instagram"></i></a></li>
                                    <li><a href="https://www.youtube.com/channel/UCZXwvBlJhY95tkBNZ7V7Vyw"><i className="fa fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
                
        )
    }
}