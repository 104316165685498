export const listUserLessonss = /* GraphQL */ `
  query ListUserLessonss(
    $filter: ModelUserLessonsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserLessonss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        lessonId
        status
        statusDate
        user {
          id
          userId
          createdAt
          updatedAt
        }
        lesson {
          id
          lessonId
          lessonName
          lessonType
          lessonDescription
          lessonImageUrl
          lessonVideoUrl
          lessonPresenter
          lessonCoins
          lessonStartDate
          display
          createdAt
          updatedAt
          courses {
            items {
              id
              courseId
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;