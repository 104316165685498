import {Auth, Analytics} from "aws-amplify";
import {API,graphqlOperation} from '@aws-amplify/api';
import { createUserAnalytics } from '../graphql/mutations';
import {isMobile} from 'react-device-detect';

const TrackPage = (userName, pageName, paramName, paramValue) => {
    try {
        if(pageName !== undefined) {
            var deviceType = "Browser";
            if(isMobile) {
                deviceType = "Mobile";
            }
            const userAnalytics = {
                userId: userName,
                page:  pageName,
                deviceType: deviceType,
                pageParamName: paramName,
                pageParamValue: paramValue,
                date: new Date().toISOString(),
            };
            //console.log(userAnalytics);
        
            API.graphql(graphqlOperation(createUserAnalytics, {input: userAnalytics})).then((response) => {
                //console.log(response.data.createUserAnalytics)               
            });

            Analytics.record({
                name: 'pageView',
                page: pageName,
                attributes: {pageParamName: paramName, pageParamValue: paramValue},
                userId: userName,
                deviceType: deviceType,
                date: new Date().toISOString(),
            });
        }
        else {
            //console.log("Page is undefined");
        }

    }
    catch (err) {
        console.log(err);
      }
};

export default TrackPage;