import React, {Component} from 'react';
import {Container, Row, Col, Table, InputGroup} from 'react-bootstrap';
import '../styles/css/style.scss';
import { PieChart } from 'react-minimal-pie-chart';
import {API,graphqlOperation} from '@aws-amplify/api'
import {Auth} from "aws-amplify";
import { getUser } from '../../src/graphql/queries'
import { listUserEventss } from '../../src/graphql/queries'
import { listUserCoursess } from '../../src/graphql/queries'
import { listUserLessonss } from '../../src/graphql/myqueries'
import { listCourseLessonss } from '../../src/graphql/queries'
import TrackPage from '../lib/TrackPage';


export default class HomeCard extends Component {


    constructor(props) {
        super(props);

        this.state = {
            username: '',
            user: '',
            myEvents: [],
            myCourses: [],
            myAwards: [],
            myCoins: [],
            myLessons: [],
            courseLessons: [],
            userCoins: [],
            level: '',
            myEventsEmptyMessage: '',

        };

        this.renderMyEvents = this.renderMyEvents.bind(this);
    }

    componentDidMount() {
        this.renderMyEvents();
      }

    

    /**
     * 
     */
    renderMyEvents = async () => {

        try {

            await Auth.currentAuthenticatedUser().then((user) => {
                this.setState({
                    username: user.attributes.email,   
                });
            });
            //console.log(this.state.username);

            await API.graphql(graphqlOperation(getUser, {id: this.state.username})).then((response) => {
                    this.setState({
                        user: response.data.getUser,
                        userCoins: response.data.getUser.coins.items,
                        myAwards: response.data.getUser.awards.items,
                    })
            });
            //console.log(this.state.user);
            //console.log(this.state.userCoins);
            //console.log(this.state.myAwards);

            var coins = this.state.userCoins.reduce((total, currentValue) => total = total + currentValue.coins,0);
            
            //var coins = this.state.myCoins.map(coin => coin.coins).reduce((a, b) => a + b)
            //var coins = this.state.user.coins;
            //console.log("Coins:"+coins);
            var statusLevel = parseInt(coins/100)+1
            //console.log("Level:"+statusLevel);
            var remainingCoins = (coins - (100*(statusLevel-1)));
            //console.log("RemainingCoins:"+remainingCoins);

            this.setState({
                myCoins: [
                    { 
                        level: statusLevel, 
                        title: 'Earned', 
                        value: remainingCoins, 
                        color: '#84A3BC' 
                    }
                ],
                level: statusLevel,
                  
            })

            await API.graphql(graphqlOperation(listUserEventss, {filter: {userId: {eq: this.state.username}}})).then((response) => {
                    this.setState({
                        myEvents: response.data.listUserEventss.items,
                    })
            });
            if(this.state.myEvents.length === 0) {
                this.setState({
                    myEventsEmptyMessage: "There are no event registrations.",
                })
            }
            //console.log(this.state.myEvents);

            // await API.graphql(graphqlOperation(listUserAwardss, {filter: {userId: {eq: this.state.username}}})).then((response) => {
            //         this.setState({
            //             myAwards: response.data.listUserAwardss.items,
            //         })
            // });
            //console.log(this.state.myAwards);

            await API.graphql(graphqlOperation(listUserCoursess, {filter: {userId: {eq: this.state.username}}})).then((response) => {
                    this.setState({
                        myCourses: response.data.listUserCoursess.items,
                    })
            });
            //console.log(this.state.myCourses);

            await API.graphql(graphqlOperation(listUserLessonss, {filter: {userId: {eq: this.state.username}}})).then((response) => {
                    this.setState({
                        myLessons: response.data.listUserLessonss.items,
                    })
            });
            //console.log(this.state.myLessons);

            await API.graphql(graphqlOperation(listCourseLessonss, {})).then((response) => {
                    this.setState({
                        courseLessons: response.data.listCourseLessonss.items,
                    })
            });
            //console.log(this.state.courseLessons);

            // Track Page Visit
            TrackPage(this.state.username, "My Home", "", "");
        }
        catch (err) {
            console.log(err);
          }
    }


    render() {

        return(
            <div id="home">
                <Container>
                    <Row className="about-text">
                        <Col sm={8} md={6}>
                            <div>
                                <h2> My Coding Coins </h2>
                                <p>Coins earned through completion of lessons, participation in events and winning awards.</p>
                                <h3 className="h3-large" align="center">Level {this.state.level}</h3>
                                <PieChart
                                    radius = {40}
                                    lineWidth={30}
                                    totalValue={100}
                                    startAngle = {270}
                                    //lengthAngle={180}
                                    //viewBoxSize={[100, 50]}
                                    label={({ dataEntry }) => dataEntry.value }
                                    labelStyle={(index) => ({
                                        fill: this.state.myCoins[index].color,
                                        fontSize: '25px',
                                        fontFamily: 'sans-serif'
                                    })}
                                    labelPosition={0}
                                    data={this.state.myCoins}
                                    style={{height:'300px'}}
                                    
                                />;
                            </div>
                        </Col>
                        <Col sm={8} md={6}>
                            <div>
                                <h2> My Awards </h2>
                                <p>My awards earned through participation in competitive events.</p>
                                <Table borderless hover>
                                    <thead  className="table-header">
                                        <tr>
                                        <th>#</th>
                                        <th>Event</th>
                                        <th>Award</th>
                                        <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-body">
                                        {
                                            this.state.myAwards.map((award, index) => {
                                                //console.log(award.awardName);
                                                //console.log(award.awardDate);
                                                //console.log(award.eventName);
                                                //console.log(index);
                                                return (
                                                    <tr key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{award.eventName}</td>
                                                        <td>{award.awardName}</td>
                                                        <td>
                                                            {new Date(award.awardDate).toLocaleDateString()}
                                                            
                                                        </td>
                                                    </tr>
                                                )
                                            })

                                        }
                                        
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                    <Row className="padding-row"></Row>
                    <Row className="about-text">
                        <Col sm={8} md={6}>
                            <div >
                                <h2> My Courses </h2>
                                <p>All of completed as well as in progress courses. Start or continue your learning journey by visiting <a href="/courses">Courses</a> page.</p>
                                
                                <Table borderless hover>
                                    <thead  className="table-header">
                                        <tr>
                                        <th>#</th>
                                        <th>Track</th>
                                        <th>Progress</th>
                                        <th>Link</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-body">
                                        {
                                            this.state.myCourses.map((course, index) => {
                                                //console.log(course.course.courseName);
                                                //console.log(course.course.courseLink);
                                                //console.log(index);
                                                const courseId = course.course.courseId;
                                                //console.log("CourseID:"+courseId);

                                                var courseLessons = 0;
                                                this.state.courseLessons.map((course, index) => {
                                                    const lessonCourseId = course.courseId;
                                                    //console.log("LessonCourseID:"+lessonCourseId);
                                                    if(courseId === lessonCourseId) {
                                                        courseLessons++;
                                                    }
                                                })
                                                //console.log("Course Lessons:"+courseLessons+" for Course:"+courseId);

                                                var completedLessons = 0;
                                                this.state.myLessons.map((lesson, index) => {
                                                    //console.log(lesson);
                                                    const lessonCourseId = lesson.lesson.courses.items[0].courseId;
                                                    //const lessonCourseId = this.state.courseLessons.get(lesson.lesson.lessonId);
                                                    //console.log("LessonCourseID:"+lessonCourseId);
                                                    //console.log("LessonCourseStatus:"+lesson.status);
                                                    if((courseId === lessonCourseId) && (lesson.status === 'Completed')) {
                                                        completedLessons++;
                                                    }
                                                })
                                                //console.log("Completed Lessons:"+completedLessons+" for Course:"+courseId);

                                                var percentComplete = 0;
                                                if(courseLessons > 0) {
                                                    percentComplete = (completedLessons/courseLessons) * 100;
                                                }
                                                if(course.course.display === "True") {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index+1}</td>
                                                            <td>{course.course.courseName}</td>
                                                            <td>{percentComplete}% <br/>({completedLessons} of {courseLessons} completed)</td>
                                                            <td><a href={course.course.courseLink} className="btn btn-lg btn-custom page-scroll">Learn</a></td>
                                                        </tr>
                                                    )
                                                }
                                            })

                                        }
                                    </tbody>
                                </Table>
                                <p></p>

                            </div>
                        </Col>
                        <Col sm={8} md={6}>
                            <div >
                                <h2> My Events </h2>
                                <p>List of upcoming events that I have signed up for. Signup for new events using <a href="/events">Events</a> link.</p>

                                <Table borderless hover>
                                    <thead  className="table-header">
                                        <tr>
                                            <th>#</th>
                                            <th>Event</th>
                                            <th>Type</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-body">
                                        {
                                            this.state.myEvents.map((event, index) => {
                                                //console.log(event.eventname);
                                                //console.log(event.eventdate);
                                                //console.log(index);
                                                return (
                                                    <tr key={index}>
                                                        <td>{event.eventId}</td>
                                                        <td>{event.event.eventName}</td>
                                                        <td>{event.event.eventType}</td>
                                                        <td>{new Date(event.event.eventStartTime).toLocaleString()}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr key="1"><td colSpan="4">{this.state.myEventsEmptyMessage}</td></tr>
 
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row className="padding-row"></Row>
                    <Row className="padding-row"></Row>
                    <Row className="padding-row"></Row>
                    <Row className="padding-row"></Row>
                    <Row className="padding-row"></Row>
                    <Row className="padding-row"></Row> */}
                </Container>
            </div>
        )
    }

}