import React, {useState} from 'react'
import { Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import "./Signup.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Auth} from 'aws-amplify';
import {SignIn} from 'aws-amplify-react';
import CustomForgotPassword from './CustomForgotPassword.js';
import { useAppContext } from "../lib/ContextLib.js";
import { useFormFields } from "../lib/HookLib.js";
import MetaDecorator from '../lib/MetaDecorator';
import TrackPage from '../lib/TrackPage';
//import {Event} from '../lib/PageTracking.js';

export default function Login() {

    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: ""
      });
    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loginError, setLoginError] = useState('');
    const { userHasAuthenticated } = useAppContext();


    /**
     * Handle login with username and password
     * 
     * @param {} event 
     */
    async function handleLogin (event) {

        event.preventDefault();
        
        var isDataValid = true;

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (fields.email.length === 0) {
            setEmailError("Please provide an email.");
            isDataValid = false;
        } 
        else if (!emailRegex.test(fields.email)) {
            setEmailError("Please provide a valid email address.");
            isDataValid = false;
        }

        if (fields.password.length === 0) {
            setPasswordError("Please provide a password.");
            isDataValid = false;
        } 

        if (!isDataValid) {
            return;
        }

        setIsLoading(true);

        try {
            const user = await Auth.signIn (fields.email.toString().toLowerCase(), fields.password).then((response) => {return response});

            //Page Tracking - Google Analytics
           //Event("Login", "Successful Login",fields.email);
        //    Analytics.record({
        //        name: "Login",
        //        attributes: {
        //            action: "Successful Login",
        //            id: fields.email,
        //        }
        //    });
            //console.log(user);
            setIsLoading(false);
            userHasAuthenticated(true);

            //Track Page
            TrackPage(fields.email.toString().toLowerCase(), "Login", "Status", "Success - User Logged In");
        }
        catch(error) {
            console.log("Error signing in", error);
            //Event("Login", "Unsuccessful Login",fields.email);
            // Analytics.record({
            //     name: "Login",
            //     attributes: {
            //         action: "Unsuccessful Login",
            //         id: fields.email,
            //     }
            // });

            setIsLoading(false);
            setLoginError("Email and/or password is invalid. Please try again.");
            setIsLoading(false);

            //Track Page
            TrackPage(fields.email.toString().toLowerCase(), "Login", "Status", "Error - Failed User Login");
            
        }
        return;
    };


    return (
        // className="form-container pt-3 h-auto"
        
        
        <div className="form-container pt-3 h-auto">
            <MetaDecorator title="gencode Login" description="gencode Login" />
            <Container className="form-parent-div">
                <Form onSubmit={(event) => handleLogin(event)}>
                    <Row>
                        {/* sm={true} md={true} lg={true} className="p-3 p-md-4 p-lg-5" */}
                        <Col className="p-3 p-md-4 p-lg-4">
                            <Row className="first-row">
                                <Col>
                                    <div className="d-flex flex-column">
                                        <div >
                                            <Row>
                                                <Col>
                                                    <p><img alt="" src="/logo_gencode_blue.png" width="180" className="d-inline-block align-top"/></p>
                                                </Col>
                                                <Col align="right">
                                                    <a href="/" className="btn btn-lg btn-custom page-scroll">Home</a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row className="name-row">
                                                <Col>

                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <h4>
                                                Inspiring the next generation of coders
                                            </h4>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="name-row">
                                <Col>

                                </Col>
                            </Row>
                            <Row className="name-row">
                                <Col>
                                    <Form.Group controlId="email">
                                        <Form.Control
                                            className={`login-input ${
                                                emailError === '' ? "login-input" : "invalid-field"
                                            }`}
                                            placeholder="Email"
                                            value={fields.email}
                                            onChange={e => {
                                                    handleFieldChange(e);
                                                    setEmailError('');
                                                }
                                            }
                                            // onChange={(event) =>
                                            //     this.setState({
                                            //         username: (event.target.elements)?event.target.elements.email.value:null,
                                            //         emailError: null,
                                            //     })
                                            //     }
                                        />
                                        <div className="error-text">
                                            {emailError}
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="name-row">
                                <Col>
                                    <Form.Group controlId="password">
                                    <Form.Control
                                        type="password"
                                        className={`login-input ${
                                            passwordError === ''
                                            ? "login-input"
                                            : "invalid-field"
                                        }`}
                                        placeholder="Password (min. 10 Characters)"
                                        value={fields.password}
                                            onChange={e => {
                                                    handleFieldChange(e);
                                                    setPasswordError('');
                                                }
                                            }
                                        // onChange={(event) =>
                                        // this.setState({
                                        //     passwordError: null,
                                        // })
                                        // }
                                    />
                                    <div className="error-text">
                                        {passwordError}
                                    </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="name-row">
                                <Col>
                                    <div className="error-text">
                                        {loginError}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="name-row">
                                <Col>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div>
                                    <Button
                                        size="lg"
                                        type="submit"
                                        block
                                        className="next-btn"
                                        disabled={isLoading}
                                    >
                                        {isLoading && (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            variant="light"
                                            style={{
                                            marginBottom: 4,
                                            }}
                                        />
                                        )}
                                        <span
                                        style={{
                                            paddingLeft: 5,
                                        }}
                                        >
                                        LOGIN
                                        </span>
                                    </Button>
                                    </div>
                                    
                                </Col>
                                
                            </Row>
                            <Row className="name-row">
                                <Col>
                                </Col>
                            </Row>
                            <Row className="name-row">
                                <Col>
                                </Col>
                            </Row>
                            <Row className="name-row">
                                <Col align="left">
                                    <div>
                                    <a href="/forgotpassword">Forgot password</a>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </Form>

            </Container>
        </div>

    );

}