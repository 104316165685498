import React, {Component} from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/css/style.scss';
import {Auth} from "aws-amplify";
import {API,graphqlOperation} from '@aws-amplify/api'
import { listEvents } from '../graphql/queries'
import {listUserEventss} from '../graphql/queries'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class AllPastEvents extends Component {

    constructor(props) {
        super(props);
        //console.log("Constructor")

        this.state = {
            pastEvents: [],
            myEvents: [], 
         };
    }

    componentDidMount() {
        this.retrieveCurrentEventsData();
      }
    

    /**
     * 
     */
    retrieveCurrentEventsData = async () => {

        try {

            await Auth.currentAuthenticatedUser().then((user) => {
                this.setState({
                    username: user.attributes.email,   
                });
            });
            //console.log(this.state.username);

            //console.log(new Date().toISOString());
            await API.graphql(graphqlOperation(listEvents, {filter: {and: {eventStartTime: {lt: new Date().toISOString()}, display: {eq: "True"}}}})).then((response) => {
                    this.setState({
                        pastEvents: response.data.listEvents.items,
                    })
            });
            //console.log(this.state.pastEvents);

            await API.graphql(graphqlOperation(listUserEventss, {filter: {userId: {eq: this.state.username}}})).then((response) => {
                this.setState({
                    myEvents: response.data.listUserEventss.items,
                })
        });
        //console.log(this.state.myEvents);

        }
        catch (err) {
            console.log(err);
          }
    }


    render() {

        return (            
            <div id="pastevents" className="justify-content-center">
                <Container>
                    <Row className="section-title">
                        <Col>
                            <h2>Past Events</h2>
                            <p>gencode provides students with several tracks at various difficulty levels ranging from the fundamentals of coding using Scratch, to more complex problems using Java, to advanced programming with Python.</p>
                        </Col>
                    </Row>
                    <Row className="service-desc">

                        {
                            this.state.pastEvents.sort((a,b) => (a.eventId > b.eventId)? 1 : -1).map((event, index) => {

                                var eventRegistration = "Did Not Attend"
                                this.state.myEvents.map((myEvent, index) => {
                                    if(event.eventId === myEvent.eventId && myEvent.status === "Attended") {
                                        eventRegistration = myEvent.status;
                                    }
                                });

                                return (
                                    <Col md={6} key={index}>
                                        <Row>
                                            <Col>
                                                <div> 
                                                    {/* <img src={event.eventImageUrl} height="120px" style={{marginBottom:30}}/> */}
                                                    <LazyLoadImage alt={event.eventName} src={event.eventImageUrl} height="120px" style={{marginBottom:30}} />
                                                    <h3>{event.eventName}</h3>
                                                    <p>
                                                        {event.eventDescription}
                                                        <br/>
                                                        <br/>
                                                        <b>Presenter: </b>{event.eventSpeaker}
                                                        <br/>
                                                        <b>Date: </b>{new Date(event.eventStartTime).toLocaleString()}
                                                        <br/>
                                                        <b>Status: </b>{eventRegistration}
                                                    </p>
                                                    
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="padding-row"></Row>
                                    </Col>
                                );

                            })
                        }
                    
                    </Row>
                    
                </Container>
            </div>
            )
    }
}