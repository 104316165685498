import React, {Component} from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/css/style.scss';
import {Auth} from "aws-amplify";
import {API,graphqlOperation} from '@aws-amplify/api'
import { listEvents } from '../graphql/queries'
import { createUserEvents } from '../graphql/mutations'
import {listUserEventss} from '../graphql/queries'
import TrackPage from '../lib/TrackPage';
import { LazyLoadImage } from 'react-lazy-load-image-component';


export default class AllUpcomingEvents extends Component {

    constructor(props) {
        super(props);

        this.state = {
           username: '',
           currentEvents: [],
           myEvents: [], 
           currentRegisteredEvent: ''
        };

        this.registerForEvent = this.registerForEvent.bind(this);

    }

    componentDidMount() {
        this.retrieveCurrentEventsData();
      }

    

    /**
     * 
     */
    retrieveCurrentEventsData = async () => {

        try {

            await Auth.currentAuthenticatedUser().then((user) => {
                this.setState({
                    username: user.attributes.email,   
                });
            });
            //console.log(this.state.username);

            //console.log(new Date().toISOString());
            await API.graphql(graphqlOperation(listEvents, {filter: {and: {eventStartTime: {gt: new Date().toISOString()}, display: {eq: "True"}}}})).then((response) => {
                    this.setState({
                        currentEvents: response.data.listEvents.items,
                    })
            });
            //console.log(this.state.currentEvents);

            await API.graphql(graphqlOperation(listUserEventss, {filter: {userId: {eq: this.state.username}}})).then((response) => {
                    this.setState({
                        myEvents: response.data.listUserEventss.items,
                    })
            });
            //console.log(this.state.myEvents);

            // Track Page
            TrackPage(this.state.username, "All Events", "", "");
        }
        catch (err) {
            console.log(err);
          }
    }

    registerForEvent = async(eventId) => {
        try {

            await Auth.currentAuthenticatedUser().then((user) => {
                this.setState({
                    username: user.attributes.email,   
                });
            });
            //console.log(this.state.username);

            const userEvent = {
                userId: this.state.username,
                eventId:  eventId,
                status: "Registered",
                statusDate: new Date().toISOString(),
            };
            //console.log(userEvent);

            await API.graphql(graphqlOperation(createUserEvents, {input: userEvent})).then((response) => {
                console.log(response.data.createUserEvents)
                if(response.data.createUserEvents && response.data.createUserEvents.event) {
                    this.setState({
                        currentRegisteredEvent: response.data.createUserEvents.event,
                    })
                }
                   
            });
            //console.log(this.state.currentRegisteredEvent);

            if(this.state.currentRegisteredEvent) {
                alert("You are registered for event: "+this.state.currentRegisteredEvent.eventName);
            }
            
           window.location.reload(false);

        }
        catch (err) {
            console.log(err);
          }
      }



    render() {

        return (            
            <div id="events" className="justify-content-center">
                <Container>
                    <Row className="section-title">
                        <Col>
                            <h2>Upcoming Events</h2>
                            <p>gencode provides students with several tracks at various difficulty levels ranging from the fundamentals of coding using Scratch, to more complex problems using Java, to advanced programming with Python.</p>
                        </Col>
                    </Row>
                    <Row className="service-desc">

                        {
                            this.state.currentEvents.sort((a,b) => (a.eventId > b.eventId)? 1 : -1).map((event, index) => {
                                var eventRegistration = "Not Registered"
                                this.state.myEvents.map((myEvent, index) => {
                                    if(event.eventId === myEvent.eventId) {
                                        eventRegistration = myEvent.status;
                                    }
                                });
                                if(eventRegistration === "Registered" || eventRegistration === "Attended") {
                                    return (
                                        <Col md={6} key={index}>
                                            <Row>
                                                <Col>
                                                    <div> 
                                                        {/* <img src={event.eventImageUrl} height="120px" style={{marginBottom:30}}/> */}
                                                        <LazyLoadImage alt={event.eventName} src={event.eventImageUrl} height="120px" style={{marginBottom:30}} />
                                                        <h3>{event.eventName}</h3>
                                                        <p>
                                                            {event.eventDescription}
                                                            <br/>
                                                            <br/>
                                                            <b>Presenter: </b>{event.eventSpeaker}
                                                            <br/>
                                                            <b>Date: </b>{new Date(event.eventStartTime).toLocaleString()}
                                                            <br/>
                                                            
                                                            <b>Staus: </b>{eventRegistration}
                                                            
                                                        </p>
                                                        
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="padding-row"></Row>
                                        </Col>
                                    );
                                }
                                else {
                                    return (
                                        <Col md={6} key={index}>
                                            <div> 
                                                {/* <img src={event.eventImageUrl} height="120px" style={{marginBottom:30}}/> */}
                                                <LazyLoadImage alt={event.eventName} src={event.eventImageUrl} height="120px" style={{marginBottom:30}} />
                                                <h3>{event.eventName}</h3>
                                                <p>
                                                    {event.eventDescription}
                                                    <br/>
                                                    <br/>
                                                    <b>Presenter: </b>{event.eventSpeaker}
                                                    <br/>
                                                    <b>Date: </b>{new Date(event.eventStartTime).toLocaleString()}
                                                    <br/>
                                                    <a onClick={() => this.registerForEvent(event.eventId)} className="btn btn-lg btn-custom page-scroll">Register</a> 
                                                </p>
                                                
                                            </div>
                                        </Col>
                                    );
                                }

                            })
                        }
                    
                    </Row>
                    
                </Container>
            </div>
            )
    }
}