import React, {Component} from 'react'
import { Fade } from 'react-bootstrap';
import '../styles/css/style.scss';
import AboutMenu from './AboutMenu.js';
import About from '../public/About.js';
import AboutPadding from './AboutPadding.js';
import MetaDecorator from '../lib/MetaDecorator';
import {Auth} from "aws-amplify";
import TrackPage from '../lib/TrackPage';
//import {PageView} from '../lib/PageTracking.js';

export default class AboutUs extends Component {

    constructor(props) {
        super(props);
        //console.log("Constructor")

        this.state = {
           

        };
    }

    componentDidMount() {
        this.logPage();
      }

    

    /**
     * 
     */
    logPage = async () => {

        try {

            const userName = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            });

            // Track Page
            TrackPage(userName, "About Us", "", "");

        }
        catch (err) {
            console.log(err);
          }
    }

    render() {

        return (
            <div className="justify-content-center">
                <MetaDecorator title="gencode About Us" description="Meet the gen{code} team." />
                <AboutMenu />
                <Fade>
                    <About />
                </Fade>
                <Fade>
                    <AboutPadding />
                </Fade>
            </div>

            )
    }
}