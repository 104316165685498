import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
//import '../styles/css/style.css';

export default class Curriculum extends Component {

    render() {

        return(
            <div id="services" className="text-center">
                <Container>
                    <Row className="section-title">
                        <Col>
                            <h2>Curriculum</h2>
                            <p>gencode provides students with several tracks at various difficulty levels ranging from the fundamentals of coding using Scratch, to more complex problems using Java, to advanced programming with Python.</p>
                        </Col>
                    </Row>
                    <Row className="service-desc">

                        <Col md={4}>
                            <div>
                                <LazyLoadImage alt="Scratch Programming" src="/scratch.jpg" style={{marginBottom:30}} height="120px" />
                                {/* <img src=  height="120px" /> */}
                                <h3>Scratch Programming</h3>
                                <p>Beginner programming track featuring a series of videos introducing basic programming concepts, scratch programming language and hands-on workshops to create a basic game using scratch.</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div> 
                                <LazyLoadImage alt="Java Programming" src="/java.svg" style={{marginBottom:30}} height="120px" />
                                {/* <img src="/java.svg" height="120px" style={{marginBottom:30}}/> */}
                                <h3>Java Programming</h3>
                                <p>Java Programming track features a series of lessons introducing java programming language concepts and hands-on Java programming workshops using Eclipse Integrated Development Environment (IDE).</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div>
                                <LazyLoadImage alt="Python Programming" src="/python.jpg" style={{marginBottom:30}} height="120px" />
                                {/* <img src="/python.jpg" height="120px" style={{marginBottom:30}}/> */}
                                <h3>Python Programming</h3>
                                <p>Python Programming track features a series of lessons introducing Python programming language concepts and hands-on Python programming workshops using PyCharm Integrated Development Environment (IDE)</p>
                            </div>
                        </Col>
                       
                    </Row>
                    
                </Container>
            </div>
        )
    }
}