/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:ffc13174-a2b3-4ea1-a150-7bf5b9f37cdd",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_Q3BxPD1oG",
    "aws_user_pools_web_client_id": "2hrfn8jf0hjok5kf6i4t57qhn3",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ow6epouefzcylb27qdy6zzpzw4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-4ewky5opizeyfdkhkr7qqjoigm",
    "aws_mobile_analytics_app_id": "1055d193361c4deeb81808cc8015e904",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
