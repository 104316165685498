import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import '../styles/css/style.scss';
import {API,graphqlOperation} from '@aws-amplify/api'
import {Auth} from "aws-amplify";
import { getCourse } from '../graphql/queries'
import TrackPage from '../lib/TrackPage';

export default class CourseDescription extends Component {


    constructor(props) {
        super(props);
        //console.log(this.props);

        this.state = {
           courseId: props.id,
           course: '',

        };
    }

    componentDidMount() {
        this.retrieveCourseData();
      }

    

    /**
     * 
     */
    retrieveCourseData = async () => {

        try {

            await Auth.currentAuthenticatedUser().then((user) => {
                this.setState({
                    username: user.attributes.email,   
                });
            });
            //console.log(this.state.username);

            await API.graphql(graphqlOperation(getCourse, {id: this.state.courseId})).then((response) => {
                    this.setState({
                        course: response.data.getCourse,
                    })
            });
            //console.log(this.state.course);

            // Track Page
            TrackPage(this.state.username, "Course Details", "CourseId", this.state.courseId);

        }
        catch (err) {
            console.log(err);
          }
    }
 

    render() {
        return(
            <div id="course">
                <Container>
                    <Row className="about-text">
                        <Col>
                            <div>
                                <h2> {this.state.course.courseName} </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="about-text">
                        <Col>
                            <div>
                                <img src={this.state.course.courseImageUrl} className="img-responsive" alt=''/>
                            </div>
                        </Col>
                    </Row>
                    <Row className="about-text">
                        <Col>
                        </Col>
                    </Row>
                    <Row className="about-text">
                        <Col>
                            <div >
                                <p>{this.state.course.courseDescription}</p>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        )
    }

}