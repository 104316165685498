import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import aboutlogo from '../styles/img/about.jpg';
import '../styles/css/style.scss';
import ReactPlayer from "react-player/lazy";

export default class Overview extends Component {

    render() {

        return(
            <div id="overview">
                <Container>
                    <Row className="overview-text">
                        
                        <Col xs={12} md={6}>
                            <div >
                                <h2>Overview</h2>
                                <p>gencode aims to provide students with an easy access platform where they can not only learn how to code, but also understand the real world applications of programming and how it can be used in our everyday lives. Through this platform, students can learn to program through online videos, hands-on projects, and live sessions.</p>
                                <p>Become a gen{'{coder}'} today by signing up using the <a href="/signup?redirect=/courses">Sign Up</a> link</p>

                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div>
                                <ReactPlayer
                                    url="https://www.youtube.com/watch?v=023NBLaFszo"
                                    width="520"
                                    controls={true}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

}