import React, {Component} from 'react'
import { Fade } from 'react-bootstrap';
import '../styles/css/style.scss';
import Menu from './Menu.js';
import Intro from './Intro.js';
import Features from './Features.js';
import About from './About.js';
import Curriculum from './Curriculum.js';
import Overview from './Overview.js';
import Contact from './Contact.js';
import MetaDecorator from '../lib/MetaDecorator';
import {Auth, Analytics} from 'aws-amplify'
import TrackPage from '../lib/TrackPage';

export default class Main extends Component {

    

    constructor(props) {
        super(props);
        //console.log("Constructor")

        this.state = {
           

        };
        
    }

    componentDidMount() {
        this.logPage();
    }

    logPage = async() => {
        try {
            const userName = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            });
            //console.log(userName);
            TrackPage(userName, "Gencode Home","","");

            //Analytics.record("Main");
        }
        catch(err) {
            TrackPage("Unauthenticated User", "Gencode Home","","");
        }
    };

    render() {
        return (
            <div className="justify-content-center">
                <MetaDecorator title="gencode Overview" description="Visit us today to learn more about the gencode platform that provides the tools to inspire next generation of coders through free on-demand hands-on education in computer programming."/>
                <Menu />
                <Fade>
                    <Intro />
                </Fade>
                <Fade>
                    <Overview />
                </Fade>
                <Fade>
                    <Features />
                </Fade>
                <Fade>
                    <Curriculum />
                </Fade>
                <Fade>
                    <About />
                </Fade>
                <Fade>
                    <Contact />
                </Fade>
               

            </div>
        )
    }
}