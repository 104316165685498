import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import { Container, Row, Col} from 'react-bootstrap';
import '../styles/css/style.scss';
import {API,graphqlOperation} from '@aws-amplify/api'
import {Auth} from "aws-amplify";
import { listCourses } from '../graphql/queries'
import { listUserCoursess } from '../graphql/queries'
import { createUserCourses } from '../graphql/mutations'
import TrackPage from '../lib/TrackPage';
import { LazyLoadImage } from 'react-lazy-load-image-component';


export default class AllCurrentCourses extends Component {

    constructor(props) {
        super(props);
        //console.log("Constructor")

        this.state = {
           username: '',
           currentCourses: [],
           myCourses: [], 
        };

        this.retrieveCoursesData = this.retrieveCoursesData.bind(this);
    }

    componentDidMount() {
        this.retrieveCoursesData();
      }

    

    /**
     * 
     */
    retrieveCoursesData = async () => {

        try {

            await Auth.currentAuthenticatedUser().then((user) => {
                this.setState({
                    username: user.attributes.email,   
                });
            });
            //console.log(this.state.username);

            //console.log(new Date().toISOString());
            await API.graphql(graphqlOperation(listCourses, {filter: {and: {courseStartDate: {lt: new Date().toISOString()}, display: {eq: "True"}}}})).then((response) => {
                    this.setState({
                        currentCourses: response.data.listCourses.items,
                    })
            });
            //console.log(this.state.currentCourses);

            await API.graphql(graphqlOperation(listUserCoursess, {filter: {userId: {eq: this.state.username}}})).then((response) => {
                    this.setState({
                        myCourses: response.data.listUserCoursess.items,
                    })
            });
            //console.log(this.state.myCourses);

            // Track Page
            TrackPage(this.state.username, "All Courses", "", "");

        }
        catch (err) {
            console.log(err);
          }
    }


    saveCourseStarted = async(courseId) => {
        try {

            await Auth.currentAuthenticatedUser().then((user) => {
                this.setState({
                    username: user.attributes.email,   
                });
            });
            //console.log(this.state.username);

            const userCourse = {
                userId: this.state.username,
                courseId:  courseId,
                status: "In Progress",
                statusDate: new Date().toISOString(),
            };
            //console.log(userCourse);

            await API.graphql(graphqlOperation(createUserCourses, {input: userCourse})).then((response) => {
                console.log(response.data.createUserCourses)
                if(!response.data.createUserCourses || !response.data.createUserCourses.course) {
                    alert("Error during saving track progress");
                }
                   
            });

        }
        catch (err) {
            console.log(err);
          }
      }


    

    render() {

        return (            
            <div id="courses" className="justify-content-center">
                <Container>
                    <Row className="section-title">
                        <Col>
                            <h2>Current Courses</h2>
                            <p>gencode provides students with several tracks at various difficulty levels ranging from the fundamentals of coding using Scratch, to more complex problems using Java, to advanced programming with Python.</p>
                        </Col>
                    </Row>

                    <Row className="service-desc">
                        {
                            this.state.currentCourses.sort((a,b) => (a.courseId > b.courseId)? 1 : -1).map((course, index) => {

                                var courseStatus = "Not Started"
                                this.state.myCourses.map((myCourse, index) => {
                                    if(course.courseId === myCourse.courseId) {
                                        courseStatus = myCourse.status;
                                    }
                                });
                                if(courseStatus === "In Progress" || courseStatus === "Completed") {
                                    return (
                                        <Col md={6} key={course.courseId}>
                                            <Row>
                                                <Col>
                                                    <div> 
                                                        
                                                        <LazyLoadImage alt={course.courseName} src={course.courseImageUrl} height="120px" style={{marginBottom:30}} />
                                                        <h3>{course.courseName}</h3>
                                                        <p>
                                                            {course.courseDescription}
                                                            <br/>
                                                            <Link to={course.courseLink} className="btn btn-lg btn-custom page-scroll">Continue Track</Link>
                                                            
                                                            {/* <a href={course.courseLink} class="btn btn-lg btn-custom page-scroll">Begin Track</a>  */}
                                                        </p>
                                                        
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="padding-row"></Row>
                                        </Col>
                                    );
                                }
                                else {
                                    return (
                                        <Col md={6} key={course.courseId}>
                                            <Row>
                                                <Col>
                                                    <div> 
                                                        <img src={course.courseImageUrl} height="120px" style={{marginBottom:30}}/>
                                                        <h3>{course.courseName}</h3>
                                                        <p>
                                                            {course.courseDescription}
                                                            <br/>
                                                            <Link to={course.courseLink} onClick={() => this.saveCourseStarted(course.courseId)} className="btn btn-lg btn-custom page-scroll">Begin Track</Link>
                                                            
                                                            {/* <a href={course.courseLink} class="btn btn-lg btn-custom page-scroll">Begin Track</a>  */}
                                                        </p>
                                                        
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="padding-row"></Row>
                                        </Col>
                                    );
                                }

                            })
                        }

                    </Row>
                    
                </Container>
            </div>
            )
    }
}