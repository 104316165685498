import React, { useState} from 'react'
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import "./Signup.scss";
import { country_list } from "./list/CountryList.js";
import { gender_list } from "./list/GenderList.js";
import { grade_list } from "./list/GradeList.js";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";
import {Auth} from 'aws-amplify';
import {API,graphqlOperation} from '@aws-amplify/api'
import { useFormFields } from "../lib/HookLib.js";
import { createUser } from '../graphql/mutations'
import { createAwards } from '../graphql/mutations'
import { createCoins } from '../graphql/mutations'
import MetaDecorator from '../lib/MetaDecorator';
import TrackPage from '../lib/TrackPage';

export default function Signup() {

    const [fields, handleFieldChange] = useFormFields({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        authCode: "",
      });
    const history = useHistory();
    const [newUser, setNewUser] = useState(null);
    const [genderVal, setGenderVal] = useState([]);
    const [grade, setGrade] = useState([]);
    const [country, setCountry] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [genderError, setGenderError] = useState('');
    const [gradeError, setGradeError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [authCodeError, setAuthCodeError] = useState('');
    const [signupError, setSignupError] = useState('');
    const [confirmCodeError, setConfirmCodeError] = useState('');


    /**
     * User Signup
     * 
     * @param {*} event 
     */
    async function handleSignup (event) {

        event.preventDefault();

        var isDataValid = true;

        if (fields.firstName.length === 0) {
            setFirstNameError("Please provide a first name.");
            isDataValid = false;
        }
        else {
            setFirstNameError("");
        }

        if (fields.lastName.length === 0) {
            setLastNameError("Please provide a last name.");
            isDataValid = false;
        }
        else {
            setLastNameError("");
        }

        if (!genderVal || genderVal === null || genderVal.length === 0) {
            setGenderError("Please select a gender.");
            isDataValid = false;
        } 
        else 
        {
            setGenderError("");
        }

        if (!grade || grade === null || grade.length === 0) {
            setGradeError("Please select a grade.");
            isDataValid = false;
        } 
        else {
            setGradeError("");
        }

        if (!country || country === null || country.length === 0) {
            setCountryError("Please select a country of residence.");
            isDataValid = false;
        } 
        else {
            setCountryError("");
        }

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (fields.email.length === 0) {
            setEmailError("Please provide an email.");
            isDataValid = false;
        } 
        else if (!emailRegex.test(fields.email)) {
            setEmailError("Please provide a valid email address.");
            isDataValid = false;
        }
        else {
            setEmailError("");
        }

        if (fields.password.length === 0) {
            setPasswordError("Please provide a password.");
            isDataValid = false;
        } 
        else if (fields.password.length > 0 && fields.password.length <= 9) {
            setPasswordError("Your password must be at least 10 characters.");
            isDataValid = false;
        }

        if (fields.password !== fields.confirmPassword) {
            setConfirmPasswordError("Confirm password does not match password.");
            isDataValid = false;
        } 

        if (!isDataValid) {
            return;
        }

        const username = fields.email.toString().toLowerCase();
        const password = fields.password;
        const email = fields.email.toString().toLowerCase();
        const gender = genderVal[0];
        const given_name = fields.firstName;
        const family_name = fields.lastName;

        setIsLoading(true);

        try {
            const user = await Auth.signUp ({
                username,
                password,
                attributes: {
                    email,
                    gender,
                    given_name,
                    family_name,
                    'custom:grade': grade[0],
                    'custom:country': country[0],
                    'custom:role': 'ROLE_USER'
                }

            });
            //console.log(user);
            setNewUser(user);

            TrackPage(fields.email.toString().toLowerCase(), "Signup", "Status", "Success - User Created - Pending Code Confirmation");

            setIsLoading(false);
        }
        catch(error) {
            console.log("Error creating user", error);
            setIsLoading(false);
            setSignupError("Error creating user. Please try again.");

            TrackPage(fields.email, "Signup", "Status", "Error - User Creation - Auth Signup");

            return;
        }
    };

    /**
     * Confirm user registration using Auth Code 
     * @param {*} event 
     */
    async function handleConfirmCode (event) {

        event.preventDefault();

        //const username = this.state.username;
        //const authCode = event.target.elements.formAuthCode.value;

        var isDataValid = true;

        if (fields.email.length === 0) {
            setAuthCodeError("Error retriving username.");
            isDataValid = false;
        }

        if (fields.authCode.length === 0) {
            setAuthCodeError("Please provide authentication code.");
            isDataValid = false;
        }

        if (!isDataValid) {
            return;
        }

        console.log(fields.authCode);

        setIsLoading(true);

        try {
            await Auth.confirmSignUp (fields.email.toString().toLowerCase(),fields.authCode);

            // Create GraphQL User Profile
            const gqUser = {
                id: fields.email.toString().toLowerCase(),
                userId: fields.email.toString().toLowerCase(),
            };

            await API.graphql(graphqlOperation(createUser, {input: gqUser})).then((response) => {
                console.log(response.data.createUser)                   
            });

            // Add Signup Coins
            const gqUserCoins = {
                coins: 20,
                coinDate:  new Date().toISOString(),
                eventName: "gen{code} signup",
                coinsUserId: fields.email.toString().toLowerCase(),
            };

            await API.graphql(graphqlOperation(createCoins, {input: gqUserCoins})).then((response) => {
                console.log(response.data.createCoins)                   
            });

            // Add Signup Award
            const gqUserAwards = {
                awardDescription: "Awarded for joining gen{code} community",
                awardDate: new Date().toISOString(),
                awardName: "gen{coder}",
                eventName: "gen{code} signup",
                awardsUserId: fields.email.toString().toLowerCase(),
            };

            await API.graphql(graphqlOperation(createAwards, {input: gqUserAwards})).then((response) => {
                console.log(response.data.createAwards)                   
            });

            // Analytics.record({
            //     name: "Signup",
            //     attributes: {
            //         action: "Successful Signup",
            //         id: fields.email,
            //     }
            // });


            setIsLoading(false);

            // Track Page
            TrackPage(fields.email.toString().toLowerCase(), "Signup", "Status", "Success - User Created - Code Confirmed");
            
            history.push("/login?redirect=/courses");
        }
        catch(error) {
            console.log("Error confirming auth code", error);

            // Analytics.record({
            //     name: "Signup",
            //     attributes: {
            //         action: "Unsuccessful Signup",
            //         id: fields.email,
            //     }
            // });

            setSignupError( "Error confirming auth code. Please try again.");

            // Track Page
            TrackPage(fields.email.toString().toLowerCase(), "Signup", "Status", "Error - User Creation - Code Confirmation");
            return;
        }
    };

    function renderSignupForm() {

        return (
            <div className="form-container pt-3 h-auto">
                <MetaDecorator title="gencode Signup" description="Unlock your future by joining the gencode community" />
                <Container className="form-parent-div">
                    <Form onSubmit={(event) => handleSignup(event)}>
                        <Row>
                            <Col sm={true} md={true} lg={true} className="p-3 p-md-5 p-lg-5">
                                <Row className="first-row">
                                    <Col sm={true} md={true} lg={true}>
                                        <div className="d-flex flex-column">
                                        <div >
                                            <Row>
                                                <Col>
                                                    <p><img alt="" src="/logo_gencode_blue.png" width="200" height="40" className="d-inline-block align-top"/></p>
                                                </Col>
                                                <Col className="text-right">
                                                    <a href="/" className="btn btn-lg btn-custom page-scroll">Home</a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row className="name-row">
                                                <Col>

                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <h4>
                                                Inspiring the next generation of coders
                                            </h4>
                                        </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <Form.Group controlId="firstName">
                                            <Form.Control
                                                className={`login-input ${
                                                    firstNameError === ''
                                                    ? ""
                                                    : "invalid-field"
                                                }`}
                                                placeholder="First Name"
                                                value={fields.firstName}
                                                onChange={e => {
                                                        handleFieldChange(e);
                                                        setFirstNameError('');
                                                    }
                                                }
                                            />
                                            <div className="error-text">
                                                {firstNameError}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <Form.Group controlId="lastName">
                                            <Form.Control
                                                className={`login-input ${
                                                    lastNameError === ''
                                                    ? ""
                                                    : "invalid-field"
                                                }`}
                                                placeholder="Last Name"
                                                value={fields.lastName}
                                                onChange={e => {
                                                        handleFieldChange(e);
                                                        setLastNameError('');
                                                    }
                                                }
                                            />
                                            <div className="error-text">
                                                {lastNameError}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        
                                        <Form.Group>
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                onChange={e => {
                                                        setGenderVal(e);
                                                        setGenderError('');
                                                    }
                                                }
                                                options={gender_list}
                                                placeholder="Gender"
                                                selected={genderVal}
                                                maxResults={250}
                                                inputProps={{
                                                className:
                                                    genderError === ''
                                                    ? "login-input"
                                                    : "invalid-field",
                                                id: "gender-input",
                                                }}
                                            />
                                            <div className="error-text">
                                                {genderError}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <Form.Group>
                                        <Typeahead
                                                id="basic-typeahead-single"
                                                onChange={e => {
                                                        setGrade(e);
                                                        setGradeError('');
                                                    }
                                                }
                                                options={grade_list}
                                                placeholder="Grade"
                                                selected={grade}
                                                maxResults={250}
                                                inputProps={{
                                                className:
                                                    gradeError === ''
                                                    ? "login-input"
                                                    : "invalid-field",
                                                id: "grade-input",
                                                }}
                                            />
                                            <div className="error-text">
                                                {gradeError}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        
                                        <Form.Group>

                                            <Typeahead
                                                id="basic-typeahead-single"
                                                onChange={e => {
                                                        setCountry(e);
                                                        setCountryError('');
                                                    }
                                                }
                                                options={country_list}
                                                placeholder="Country of Residence"
                                                selected={country}
                                                maxResults={250}
                                                inputProps={{
                                                className:
                                                    countryError === ''
                                                    ? "login-input"
                                                    : "invalid-field",
                                                id: "country-input",
                                                }}
                                            />
                                            <div className="error-text">
                                                {countryError}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="name-row">
                                    <Col>
                                        <Form.Group controlId="email">
                                            <Form.Control
                                                className={`login-input ${
                                                    emailError === '' ? "login-input" : "invalid-field"
                                                }`}
                                                placeholder="Email"
                                                value={fields.email}
                                                onChange={e => {
                                                        handleFieldChange(e);
                                                        setEmailError('');
                                                    }
                                                }
                                            />
                                            <div className="error-text">
                                                {emailError}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <Form.Group controlId="password">
                                        <Form.Control
                                            type="password"
                                            className={`login-input ${
                                                passwordError === ''
                                                ? "login-input"
                                                : "invalid-field"
                                            }`}
                                            placeholder="Password (min. 10 characters)"
                                            value={fields.password}
                                            onChange={e => {
                                                    handleFieldChange(e);
                                                    setPasswordError('');
                                                }
                                            }
                                        />
                                        <div className="error-text">
                                            {passwordError}
                                        </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <Form.Group controlId="confirmPassword">
                                        <Form.Control
                                            type="password"
                                            className={`login-input ${
                                                confirmPasswordError === ''
                                                ? "login-input"
                                                : "invalid-field"
                                            }`}
                                            placeholder="Confirm Password"
                                            value={fields.confirmPassword}
                                            onChange={e => {
                                                    handleFieldChange(e);
                                                    setConfirmPasswordError('');
                                                }
                                            }
                                        />
                                        <div className="error-text">
                                            {confirmPasswordError}
                                        </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <div className="error-text">
                                            {signupError}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                        <Button
                                            size="lg"
                                            type="submit"
                                            block
                                            className="next-btn"
                                            disabled={isLoading}
                                        >
                                            {isLoading && (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="light"
                                                style={{
                                                marginBottom: 4,
                                                }}
                                            />
                                            )}
                                            <span
                                            style={{
                                                paddingLeft: 5,
                                            }}
                                            >
                                            SIGN UP
                                            </span>
                                        </Button>
                                        </div>
                                    </Col>
                                    
                                </Row>
                                

                            </Col>
                        </Row>

                    </Form>

                </Container>
            </div>
        );
    }

    function renderConfirmAuthCodeForm() {

        return (
            <div className="form-container pt-3 h-auto">
                <Container className="form-parent-div">
                    <Form onSubmit={(event) => handleConfirmCode(event)}>
                        <Row>
                            <Col sm={true} md={true} lg={true} className="p-3 p-md-4 p-lg-5">
                                <Row className="first-row">
                                    <Col sm={true} md={true} lg={true}>
                                        <div className="d-flex flex-column">
                                        <div >
                                            <Row>
                                                <Col>
                                                    <p><img alt="" src="/logo_gencode.png" width="200" height="40" className="d-inline-block align-top"/></p>
                                                </Col>
                                                <Col className="text-right">
                                                    <a href="/" className="btn btn-lg btn-custom page-scroll">Home</a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <h4>
                                                Inspiring the next generation of coders
                                            </h4>
                                        </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <Form.Group controlId="authCode">
                                            <Form.Control
                                                className={`login-input ${
                                                    authCodeError === ''
                                                    ? "login-input"
                                                    : "invalid-field"
                                                }`}
                                                placeholder="Enter Auth Code from Email"
                                                value={fields.authCode}
                                                onChange={e => {
                                                        handleFieldChange(e);
                                                        setAuthCodeError('');
                                                    }
                                                }
                                            />
                                            <div className="error-text">
                                                {authCodeError}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="name-row">
                                    <Col>
                                        <div className="error-text">
                                            {confirmCodeError}
                                        </div>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col md={6}>
                                        <div>
                                        <Button
                                            size="lg"
                                            type="submit"
                                            block
                                            className="next-btn"
                                            disabled={isLoading}
                                        >
                                            {isLoading && (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="light"
                                                style={{
                                                marginBottom: 4,
                                                }}
                                            />
                                            )}
                                            <span
                                            style={{
                                                paddingLeft: 5,
                                            }}
                                            >
                                            CONFIRM CODE
                                            </span>
                                        </Button>
                                        </div>
                                    </Col>
                                    
                                </Row>

                            </Col>
                        </Row>

                    </Form>

                </Container>
            </div>

        )
    }

    return (
        <div>
          {newUser === null ? renderSignupForm() : renderConfirmAuthCodeForm()}
        </div>
      );

}