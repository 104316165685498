import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';

export default class Features extends Component {

    render() {

        return(
                <div id="features" className="text-center">
                    <Container>
                        <Row className="section-title">
                            <Col sm={{ span: 12, offset: 2 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }}>
                                <div>
                                    <h2>Features</h2>
                                </div>
                            </Col>
                        </Row>
                        <Row className="service-desc">
                            <Col md={6}>
                                <div>
                                    <i className="fa fa-video-camera"></i>
                                    <div>
                                        <h3>Courses on Demand</h3>
                                        <p>Develop computer science knowledge at your pace through on-demand courses in various programming languages such as Scratch, Java and Python. </p>
                                    </div>
                                </div>
                                <div>
                                    <Row className="padding-row">
                                        <Col>

                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div> 
                                    <i className="fa fa-mobile-phone"></i>
                                    <div>
                                        <h3>Technology Frontiers</h3>
                                        <p>Keep up with the technology through articles featuring review of the latest products and technologies that we use in our day to day life.</p>
                                    </div>
                                </div>
                                <div>
                                    <Row className="padding-row">
                                        <Col>

                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div> 
                                    <i className="fa fa-code"></i>
                                    <div>
                                        <h3>Codethons</h3>
                                        <p>Show your creativity and earn the bragging rights by participating in and winning coding competitions tailored for various skill levels ranging from beginner to advanced.</p>
                                    </div>
                                </div>
                                <div>
                                    <Row className="padding-row">
                                        <Col>

                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col md={6}>
                            <div>
                                <i className="fa fa-bullhorn"></i>
                                <div>
                                    <h3>Live Workshops</h3>
                                    <p>Live interactive workshops and discussion groups to promote community-based learning through collboration and knowledge sharing.</p>
                                </div>
                            </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            
        )
    }

}