import React from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import Home from "./home/Home.js";
import Profile from "./user/Profile.js";
import Main from "./public/Main.js";
import Login from "./user/Login.js";
import ForgotPassword from "./user/ForgotPassword.js";
import Signup from "./user/Signup.js";
import Signout from "./user/Signout.js";
import NotFound from "./error/NotFound.js";
import AllCourses from "./courses/AllCourses.js"
import CourseOverview from "./courses/CourseOverview.js"
import AllEvents from "./events/AllEvents.js"
import AboutUs from "./about/AboutUs.js"
import ContactUs from "./contact/ContactUs.js"
import AuthenticatedRoute from "./lib/AuthenticatedRoute.js";
import UnauthenticatedRoute from "./lib/UnauthenticatedRoute.js";
// import withGAPageTracker from './lib/withGAPageTracker.js';

export default function Routes() {
  return (
    <Router>
        <Switch>
            <AuthenticatedRoute exact path="/home">
                <Home />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/profile">
                <Profile />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/logout">
                <Signout />
            </AuthenticatedRoute>
            <UnauthenticatedRoute exact path="/login">
                <Login />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/forgotpassword">
                <ForgotPassword />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute  exact path="/signup" >
                <Signup />
            </UnauthenticatedRoute>
            <AuthenticatedRoute exact path="/courses">
                <AllCourses />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/courseoverview/:id">
                <CourseOverview />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/events">
                <AllEvents />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/about">
                <AboutUs />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/contact">
                <ContactUs />
            </AuthenticatedRoute>
            <Route exact path="/">
                <Main />
            </Route>
            <Route>
                <NotFound />
            </Route>
        </Switch>
    </Router>
  );
}
