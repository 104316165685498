import React, {Component} from 'react'
import { Fade } from 'react-bootstrap';
import '../styles/css/style.scss';
import CourseMenu from './CourseMenu.js';
import AllCurrentCourses from './AllCurrentCourses.js';
import AllUpcomingCourses from './AllUpcomingCourses.js';
import MetaDecorator from '../lib/MetaDecorator';
//import {PageView} from '../lib/PageTracking.js';

export default class AllCourses extends Component {

    constructor(props) {
        super(props);
        //console.log("Constructor")

        this.state = {
           

        };
    }

    componentDidMount() {
    }

    render() {

        return (
            <div className="justify-content-center">
                <MetaDecorator title="gen{code} Courses" description="Free computing overview and programming courses for elementary, middle school and high school students." />
                <CourseMenu />
                <Fade>
                    <AllCurrentCourses />
                </Fade>
                <Fade>
                    <AllUpcomingCourses />
                </Fade>
            </div>

            )
    }
}