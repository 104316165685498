import React, {Component} from 'react'
import { Fade } from 'react-bootstrap';
import '../styles/css/style.scss';
import EventMenu from './EventMenu.js';
import AllUpcomingEvents from './AllUpcomingEvents.js';
import AllPastEvents from './AllPastEvents.js';
import MetaDecorator from '../lib/MetaDecorator';
//import {PageView} from '../lib/PageTracking.js';


export default class AllEvents extends Component {

    constructor(props) {
        super(props);
        //console.log("Constructor")

        this.state = {
           

        };
    }

    // componentDidMount() {
    //     PageView();
    // }

    render() {

        return (
            <div className="justify-content-center">
                <MetaDecorator title="gencode Events" description="Enhance your computers and programming knowledge through participation in gencode interactive events and workshops." />
                <EventMenu />
                <Fade>
                    <AllUpcomingEvents />
                </Fade>
                <Fade>
                    <AllPastEvents />
                </Fade>
            </div>

            )
    }
}