import React, {Component} from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default class CourseMenu extends Component {

    render() {

        return(
            <Navbar sticky="top" variant="dark" expand="md" collapseOnSelect={true} style={{backgroundColor: '#243665'}}>
                <Navbar.Brand href="#home" className="pl-1 pl-md-4" style={{paddingRight: 25,color: '#8BD8BD'}}>
                    <img alt="" src="/logo_gencode.png" width="200" height="40" className="d-inline-block align-top"/>
                
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Item>
                            <Nav.Link href="/home">Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="/courses" active>Courses</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="/events">Events</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="/about">About</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="/contact">Contact</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                    <NavDropdown title="PROFILE" id="collasible-nav-dropdown">
                        <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                        <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
                    </NavDropdown>
                    </Nav>
                </Navbar.Collapse>    
            </Navbar>
        )
    }

}