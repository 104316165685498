import React, {Component} from 'react'
import { Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import "./Signup.scss";
import { country_list } from "./list/CountryList.js";
import { gender_list } from "./list/GenderList.js";
import { grade_list } from "./list/GradeList.js";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";
import {API,graphqlOperation} from '@aws-amplify/api'
import {Auth} from "aws-amplify";
import { getUser } from '../../src/graphql/queries'
import MetaDecorator from '../lib/MetaDecorator';
import {PageView} from '../lib/PageTracking.js';
import TrackPage from '../lib/TrackPage';

export default class Profile extends Component{

    constructor(props) {
        super(props);
        //console.log("Constructor")

        this.state = {
            formType: 'login',
            cognitoUser: '',
            gqUser: '',
            firstName: '',
            firstNameError: '',
            lastName: '',
            lastNameError: '',
            gender: [],
            genderError: '',
            grade: [],
            gradeError: '',
            country: [],
            countryError: '',
            updateProfileError: '',
            updateProfileSuccess: '',
            setIsProfileLoading: false,
            setIsChangePasswordLoading: false,
            password: '',
            passwordError: '',
            newPassword: '',
            newPasswordError: '',
            confirmPassword: '',
            confirmPasswordError: '',
            changePasswordError: '',
            changePasswordSuccess: '',
            formUpdateError: false,

        };

        this.renderProfile = this.renderProfile.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
    }

    componentDidMount() {
        //console.log("ComponentDidMount");
        //PageView();
        this.renderProfile();
    }

    
    renderProfile = async () => {
        //console.log("renderProfile");
        try {

            await Auth.currentAuthenticatedUser().then((user) => {
                this.setState({
                    username: user.attributes.email,   
                    cognitoUser: user.attributes,
                });
            });
            //console.log(this.state.cognitoUser);

            await API.graphql(graphqlOperation(getUser, {id: this.state.username})).then((response) => {
                    this.setState({
                        gqUser: response.data.getUser,
                    })
            });
            //console.log(this.state.gqUser);

            //console.log(this.state.formUpdateError);
            if(!this.state.formUpdateError) {
                this.setState({
                    firstName: this.state.cognitoUser.given_name,
                    lastName: this.state.cognitoUser.family_name,
                    gender: [this.state.cognitoUser.gender],
                    grade: [this.state.cognitoUser["custom:grade"]],
                    country: [this.state.cognitoUser["custom:country"]],
                })
            }

            //Track Page
            TrackPage(this.state.username, "Profile", "", "");

        }
        catch (err) {
            console.log(err);
          }
    }

    /**
     * Update user profiles
     */
    updateProfile = async () => {
        //console.log("updateProfile");
        var isDataValid = true;

        if (this.state.firstName.length === 0) {
            this.setState({
                firstNameError: "Please provide a first name.",
            })
            isDataValid = false;
        }
        else {
            this.setState({
                firstNameError: "",
            })
        }

        if (this.state.lastName.length === 0) {
            this.setState({
                lastNameError: "Please provide a last name.",
            })
            isDataValid = false;
        }
        else {
            this.setState({
                lastNameError: "",
            })
        }

        if (!this.state.gender || this.state.gender === null || this.state.gender.length === 0) {
            this.setState({
                genderError: "Please select a gender.",
            })
            isDataValid = false;
        } 
        else 
        {
            this.setState({
                genderError: "",
            })
        }

        if (!this.state.grade || this.state.grade === null || this.state.grade.length === 0) {
            this.setState({
                gradeError: "Please select a grade.",
            })
            isDataValid = false;
        } 
        else 
        {
            this.setState({
                gradeError: "",
            })
        }

        if (!this.state.country || this.state.country === null || this.state.country.length === 0) {
            this.setState({
                countryError: "Please select a country.",
            })
            isDataValid = false;
        } 
        else 
        {
            this.setState({
                countryError: "",
            })
        }

        //console.log(this.state.lastName);

        if (!isDataValid) {
            this.setState({
                formUpdateError: true,
            })
            //console.log(this.state.formUpdateError);
            return;
        }

        this.setState({
            setIsProfileLoading: true,
        })

        

        try {
            const currentUserInfo = await Auth.currentAuthenticatedUser();
            const user = await Auth.updateUserAttributes (currentUserInfo, {
                    gender: this.state.gender[0],
                    given_name: this.state.firstName,
                    family_name: this.state.lastName,
                    'custom:grade': this.state.grade[0],
                    'custom:country': this.state.country[0],
                });
            //console.log(user);
            //setNewUser(user);

            // Analytics.record({
            //     name: "Profile",
            //     attributes: {
            //         action: "Successful Profile Update",
            //         id: this.state.username,
            //     }
            // });

            this.setState({
                setIsProfileLoading: false,
                formUpdateError: false,
                updateProfileSucess: "Profile updated successfully.",
                updateProfileError: "",
            })

             //Track Page
             TrackPage(this.state.username, "Profile", "Update", "Success - Profile Updated");

            alert("User Profile updated successfully");

            
        }
        catch(error) {
            console.log("Error updating user", error);

            // Analytics.record({
            //     name: "Profile",
            //     attributes: {
            //         action: "Unsuccessful Profile Update",
            //         id: this.state.username,
            //     }
            // });

            this.setState({
                setIsProfileLoading: false,
                formUpdateError: true,
                updateProfileError: "Error updating user profile. Please try again."
            })

            //Track Page
            TrackPage(this.state.username, "Profile", "Update", "Error - Profile Updated");

            //alert("Error updating User Profile. Please try again.");
            return;
        }
        //window.location.reload(false);

    }

    changePassword = async () => {
        //console.log("changePassword");
        var isDataValid = true;

        if (this.state.password.length === 0) {
            this.setState({
                passwordError: "Please enter current passsword.",
            })
            isDataValid = false;
        } 
        else if (this.state.newPassword.length === 0) {
            this.setState({
                newPasswordError: "Please enter a new passsword.",
            })
            isDataValid = false;
        } 
        else if (this.state.newPassword.length > 0 && this.state.newPassword.length <= 9) {
            this.setState({
                newPasswordError: "Your password must be at least 10 characters.",
            })
            isDataValid = false;
        }
        else if (this.state.newPassword !== this.state.confirmPassword) {
            this.setState({
                confirmPasswordError: "Confirm password does not match password.",
            })
            isDataValid = false;
        }     

        if (!isDataValid) {
            this.setState({
                changePasswordSucess: '',
                formUpdateError: true,
            })
            return;
        }

        this.setState({
            setIsChangePasswordLoading: true,
        })

        try {
            const currentUserInfo = await Auth.currentAuthenticatedUser();
            const user = await Auth.changePassword (currentUserInfo, this.state.password, this.state.newPassword);
            console.log("Password changed successfully");

            // Analytics.record({
            //     name: "Password",
            //     attributes: {
            //         action: "Successful Change Password",
            //         id: this.state.username,
            //     }
            // });

            this.setState({
                setIsChangePasswordLoading: false,
                changePasswordSucess: "Profile updated successfully.",
                changePasswordError: "",
                formUpdateError: false,

            })

            //Track Page
            TrackPage(this.state.username, "Profile", "Change Password", "Success - Password Changed");

            alert("Password changed successfully");
            return;
        }
        catch(error) {
            console.log("Error changing password", error);

            // Analytics.record({
            //     name: "Password",
            //     attributes: {
            //         action: "Unsuccessful Change Password",
            //         id: this.state.username,
            //     }
            // });

            this.setState({
                setIsChangePasswordLoading: false,
                changePasswordError: "Error changing password. Please try again",
                formUpdateError: true,
            })

             //Track Page
             TrackPage(this.state.username, "Profile", "Change Password", "Error - Failed Password Change");
        }
        return;

    }

    

    render() {
        const formType = this.state.formType
        //console.log(formType)

        return (
            <div className="form-container pt-3 h-auto">
                <MetaDecorator title="gencode Profile" description="Manage gencode user profile" />
                <Container className="form-parent-div">
                    <Row>
                        <Col sm={true} md={true} lg={true} className="p-4 p-md-4 p-lg-4">
                            <Row className="first-row">
                                <Col>
                                    <div className="d-flex flex-column">
                                        <div >
                                            <Row>
                                                <Col>
                                                    <p><img alt="" src="/logo_gencode_blue.png" width="200" height="40" className="d-inline-block align-top"/></p>
                                                </Col>
                                                <Col className="text-right">
                                                    <a href="/home" className="btn btn-lg btn-custom page-scroll">Home</a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row className="name-row">
                                                <Col>

                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <h4>
                                                Inspiring the next generation of coders
                                            </h4>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Form>
                                <Row className="name-row">
                                    <Col md={4}>
                                        <p>Email:</p>
                                    </Col>
                                    <Col md={8}>
                                        <p>{this.state.cognitoUser.email}</p>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col md={4}>
                                        <p>First Name:</p>
                                    </Col>
                                    <Col md={8}>
                                        
                                        <Form.Group controlId="formFirstName">
                                            <Form.Control
                                                className={`login-input ${
                                                    this.state.firstNameError === '' ? "" : "invalid-field"
                                                }`}
                                                placeholder="First Name"
                                                onChange={(event) =>
                                                    this.setState({
                                                        firstName: (event.target)?event.target.value:'',
                                                        firstNameError: '',
                                                    })
                                                    }
                                                defaultValue={this.state.firstName}
                                            />
                                            <div className="error-text">
                                                {this.state.firstNameError}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col md={4}>
                                        <p>Last Name:</p>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Group controlId="formLastName">
                                            <Form.Control
                                                className={`login-input ${
                                                    this.state.lastNameError === '' ? "" : "invalid-field"
                                                }`}
                                                placeholder="Last Name"
                                                onChange={(event) => {
                                                    //console.log(event.target.value);
                                                    this.setState({
                                                        lastName:  (event.target)?event.target.value:'',
                                                        lastNameError: '',
                                                    })
                                                    } 
                                                    }
                                                defaultValue={this.state.lastName}
                                            />
                                            <div className="error-text">
                                                {this.state.lastNameError}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col md={4}>
                                        <p>Gender:</p>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Group>
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                onChange={event => {
                                                        this.setState({
                                                            gender: event,
                                                            genderError: '',
                                                        })
                                                    }
                                                }
                                                options={gender_list}
                                                placeholder="Select Gender"
                                                selected={this.state.gender}
                                                maxResults={250}
                                                inputProps={{
                                                className:
                                                    this.state.genderError === ''
                                                    ? "login-input"
                                                    : "invalid-field",
                                                id: "gender-input",
                                                }}
                                            />
                                            <div className="error-text">
                                                {this.state.genderError}
                                            </div>
                                        </Form.Group>
                                        
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col md={4}>
                                        <p>Grade:</p>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Group>
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                onChange={event => {
                                                        this.setState({
                                                            grade: event,
                                                            gradeError: '',
                                                        })
                                                    }
                                                }
                                                options={grade_list}
                                                placeholder="Select Grade"
                                                selected={this.state.grade}
                                                maxResults={250}
                                                inputProps={{
                                                className:
                                                    this.state.gradeError === ''
                                                    ? "login-input"
                                                    : "invalid-field",
                                                id: "gender-input",
                                                }}
                                            />
                                            <div className="error-text">
                                                {this.state.gradeError}
                                            </div>
                                        </Form.Group>
                                        
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col md={4}>
                                        <p>Country:</p>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Group>
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                onChange={event => {
                                                        this.setState({
                                                            country: event,
                                                            countryError: '',
                                                        })
                                                    }
                                                }
                                                options={country_list}
                                                placeholder="Select Country"
                                                selected={this.state.country}
                                                maxResults={250}
                                                inputProps={{
                                                className:
                                                    this.state.countryError === ''
                                                    ? "login-input"
                                                    : "invalid-field",
                                                id: "gender-input",
                                                }}
                                            />
                                            <div className="error-text">
                                                {this.state.countryError}
                                            </div>
                                        </Form.Group>
                                        
                                    </Col>
                                </Row>
                                
                                <Row className="name-row">
                                    <Col>
                                        <div className="error-text">
                                            {this.state.updateProfileError}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <div>
                                            {this.state.updateProfileSuccess}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>

                                    </Col>
                                </Row>
                                <Row>
                                        <Col md={8} lg={8}>
                                        <div>
                                        <Button
                                            size="lg"
                                            type="button"
                                            block
                                            className="next-btn"
                                            onClick={() => this.updateProfile()}
                                            disabled={this.state.setIsProfileLoading}
                                        >
                                            {this.state.setIsProfileLoading && (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="light"
                                                style={{
                                                marginBottom: 4,
                                                }}
                                            />
                                            )}
                                            <span
                                            style={{
                                                paddingLeft: 5,
                                            }}
                                            >
                                            UPDATE PROFILE
                                            </span>
                                        </Button>
                                        </div>
                                        
                                    </Col>
                                    
                                </Row>
                            </Form>
                            <Row className="name-row">
                                <Col>
                                </Col>
                            </Row>
                            <Row className="name-row">
                                <Col>
                                </Col>
                            </Row>
                            <Row className="name-row">
                                <Col sm={12} md={8} lg={8}>
                                </Col>
                            </Row>
                            <Form>
                                <Row className="name-row">
                                    <Col md={4}>
                                        <p>Password:</p>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Group controlId="password">
                                        <Form.Control
                                            type="password"
                                            className={`login-input ${
                                                this.state.passwordError === ''
                                                ? ""
                                                : "invalid-field"
                                            }`}
                                            placeholder="Current Password"
                                            defaultValue=''
                                            onChange={e => {
                                                    //console.log(e.target.value);
                                                    this.setState({
                                                        password: e.target.value,
                                                        passwordError: '',
                                                    })
                                                }
                                            }
                                        />
                                        <div className="error-text">
                                            {this.state.passwordError}
                                        </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col md={4}>
                                        <p>New Password:</p>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Group controlId="password">
                                        <Form.Control
                                            type="password"
                                            className={`login-input ${
                                                this.state.newPasswordError === ''
                                                ? ""
                                                : "invalid-field"
                                            }`}
                                            placeholder="Password (min. 10 chars)"
                                            defaultValue=''
                                            onChange={e => {
                                                    //console.log(e.target.value);
                                                    this.setState({
                                                        newPassword: e.target.value,
                                                        newPasswordError: '',
                                                    })
                                                }
                                            }
                                        />
                                        <div className="error-text">
                                            {this.state.newPasswordError}
                                        </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col md={4}>
                                        <p>Confirm Password:</p>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Group controlId="confirmPassword">
                                        <Form.Control
                                            type="password"
                                            className={`login-input ${
                                                this.state.confirmPasswordError === ''
                                                ? ""
                                                : "invalid-field"
                                            }`}
                                            placeholder="Confirm Password"
                                            defaultValue=''
                                            onChange={e => {
                                                    //console.log(e.target.value);
                                                    this.setState({
                                                        confirmPassword: e.target.value,
                                                        confirmPasswordError: '',
                                                    })
                                                }
                                            }
                                        />
                                        <div className="error-text">
                                            {this.state.confirmPasswordError}
                                        </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <div className="error-text">
                                            {this.state.changePasswordError}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <div>
                                            {this.state.changePasswordSuccess}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                        <Col md={8} lg={8}>
                                        <div>
                                        <Button
                                            size="lg"
                                            type="button"
                                            block
                                            className="next-btn"
                                            onClick={() => this.changePassword()}
                                            disabled={this.state.setIsChangePasswordLoading}
                                        >
                                            {this.state.setIsChangePasswordLoading && (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="light"
                                                style={{
                                                marginBottom: 4,
                                                }}
                                            />
                                            )}
                                            <span
                                            style={{
                                                paddingLeft: 5,
                                            }}
                                            >
                                            CHANGE PASSWORD
                                            </span>
                                        </Button>
                                        </div>
                                        
                                    </Col>
                                    
                                </Row>
                            </Form>

                        </Col>
                    </Row>
                </Container>
            </div>

        )
    }

}