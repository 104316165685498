import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import '../styles/css/style.scss';

export default class AboutPadding extends Component {

    render() {

        return(
            <div id="aboutpadding">
                <Container>
                    <Row className="about-text">
                        <Col xs={12} md={6}>
                           
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

}