import React, {useState, useEffect} from 'react'
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import "./Signup.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useAppContext } from "../lib/ContextLib.js";
import {Auth} from 'aws-amplify';
import MetaDecorator from '../lib/MetaDecorator';
import {Event} from '../lib/PageTracking.js';
import TrackPage from '../lib/TrackPage';


export default function Signout() {

    const history = useHistory();
    const [loggedInUser, setLoggedInUser] = useState('');
    const [signoutError, setSignoutError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { userHasAuthenticated } = useAppContext();

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((user) => {setLoggedInUser(user.attributes.email);})
      });

    /**
     * Sign out current user
     * 
     * @param {*} event 
     */
    async function handleSignout (event) {
        event.preventDefault();

        var isDataValid = true;

        if (loggedInUser && (loggedInUser.length === 0)) {
            setSignoutError("No user is signed in");
            isDataValid = false;
        } 
        else {
            setSignoutError('');
        }

        if (!isDataValid) {
            return;
        }

        setIsLoading(true);

        try {
            Auth.signOut({ global: true });
            //Event("Logout", "Successful Logout",loggedInUser);
            setIsLoading(false);

            userHasAuthenticated(false);

            // Analytics.record({
            //     name: "Logout",
            //     attributes: {
            //         action: "Successful Logout",
            //         id: loggedInUser,
            //     }
            // });
            //Track Page
            TrackPage(loggedInUser, "Logout", "Status", "Success - User Logged Out");
        }
        catch(error) {
            console.log("Error signing out", error);
            //Event("Logout", "Unsuccessful Logout",loggedInUser);
            // Analytics.record({
            //     name: "Logout",
            //     attributes: {
            //         action: "Unuccessful Logout",
            //         id: loggedInUser,
            //     }
            // });
            setIsLoading(false);
            setSignoutError("Error during signout. Please try again.");

            //Track Page
            TrackPage(loggedInUser, "Logout", "Status", "Error - Failed User Log Out");

            return;
        }
        
        //console.log(loggedInUser+" signed out successfully");
        history.push("/");

        return;
    };

    /**
     * Display Signout form
     */
    function renderSignoutForm() {
        return (
            <div className="form-container2 pt-3 h-auto">
                <MetaDecorator title="gencode Logout" description="" />
                <Container className="form-parent-div">
                    <Form onSubmit={(event) => handleSignout(event)}>
                        <Row>
                            <Col sm={true} md={true} lg={true} className="p-3 p-md-4 p-lg-5">
                                <Row className="first-row">
                                    <Col sm={true} md={true} lg={true}>
                                        <div className="d-flex flex-column">
                                        <div >
                                            <Row>
                                                <Col>
                                                    <p><img alt="" src="/logo_gencode_blue.png" width="200" height="40" className="d-inline-block align-top"/></p>
                                                </Col>
                                                <Col className="text-right">
                                                    <a href="/home" className="btn btn-lg btn-custom page-scroll">Home</a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row className="name-row">
                                                <Col>

                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <h4>
                                                Inspiring the next generation of coders
                                            </h4>
                                        </div>
                                        </div>
                                    </Col>
                                </Row>
                            
                                <Row className="name-row">
                                    <Col sm={12} md={12} lg={12}>
                                        <div className="info-text">
                                            <h5>Logout : {loggedInUser}</h5>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col sm={12} md={8} lg={8}>
                                        <div className="error-text">
                                            {signoutError}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8} lg={6}>
                                        <div>
                                        <Button
                                            size="lg"
                                            type="submit"
                                            block
                                            className="next-btn"
                                            disabled={isLoading}
                                        >
                                            {isLoading && (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="light"
                                                style={{
                                                marginBottom: 4,
                                                }}
                                            />
                                            )}
                                            <span
                                            style={{
                                                paddingLeft: 5,
                                            }}
                                            >
                                            SIGNOUT
                                            </span>
                                        </Button>
                                        </div>
                                        
                                    </Col>
                                    
                                </Row>


                            </Col>
                        </Row>

                    </Form>
                </Container>
            </div>

        )
    }

    return(
        <div>
            {renderSignoutForm()}
        </div>
    )

}