import React, {Component} from 'react'
import { Fade } from 'react-bootstrap';
import '../styles/css/style.scss';
import HomeMenu from './HomeMenu.js';
import HomeCard from './HomeCard.js';
import MetaDecorator from '../lib/MetaDecorator';
//import {PageView} from '../lib/PageTracking.js';


export default class Home extends Component {

    constructor(props) {
        super(props);
        //console.log("Constructor")

        this.state = {
           

        };
    }

    // componentDidMount() {
    //     PageView();
    // }


    render() {

        return (
            <div className="justify-content-center">
                <MetaDecorator title="My gencode Home" description="gencode User portal to track course progress, event registrations and awards" />
                <HomeMenu />
                <Fade>
                    <HomeCard />
                </Fade>
            </div>
        )
    }
}