import React, {Component} from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/css/style.scss';
import {API,graphqlOperation} from '@aws-amplify/api'
import {Auth} from "aws-amplify";
import { listCourses } from '../graphql/queries'
import { LazyLoadImage } from 'react-lazy-load-image-component';


export default class AllUpcomingCourses extends Component {

    constructor(props) {
        super(props);
        //console.log("Constructor")

        this.state = {
            username: '',
            upcomingCourses: [],
            

        };

        this.retrieveCourseData = this.retrieveCourseData.bind(this);
    }

    componentDidMount() {
        this.retrieveCourseData();
      }
    

    /**
     * 
     */
    retrieveCourseData = async () => {

        try {

            await Auth.currentAuthenticatedUser().then((user) => {
                this.setState({
                    username: user.attributes.email,   
                });
            });
            //console.log(this.state.username);

            //console.log(new Date().toISOString());

            await API.graphql(graphqlOperation(listCourses, {filter: {and: {courseStartDate: {gt: new Date().toISOString()}, display: {eq: "True"}}}})).then((response) => {
                    this.setState({
                        upcomingCourses: response.data.listCourses.items,
                    })
            });
            //console.log(this.state.upcomingCourses);

        }
        catch (err) {
            console.log(err);
          }
    }


    

    render() {

        return (            
            <div id="upcomingcourses" className="justify-content-center">
                <Container>
                    <Row className="section-title">
                        <Col>
                            <h2>Upcoming Courses</h2>
                            <p>gencode provides students with several tracks at various difficulty levels ranging from the fundamentals of coding using Scratch, to more complex problems using Java, to advanced programming with Python.</p>
                        </Col>
                    </Row>
                    <Row className="service-desc">
                        {
                            this.state.upcomingCourses.sort((a,b) => (a.courseId > b.courseId)? 1 : -1).map((course, index) => {

                                
                                return (
                                    <Col md={6} key={course.courseId}>
                                        <Row>
                                            <Col>
                                                <div> 
                                                    {/* <img src={course.courseImageUrl} height="120px" style={{marginBottom:30}}/> */}
                                                    <LazyLoadImage alt={course.courseName} src={course.courseImageUrl} height="120px" style={{marginBottom:30}} />
                                                    <h3>{course.courseName}</h3>
                                                    <p>
                                                        {course.courseDescription}
                                                        <br/>
                                                        {/* <a href="#features" class="btn btn-lg btn-custom page-scroll">Begin Track</a>  */}
                                                    </p>
                                                    
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="padding-row"></Row>
                                    </Col>
                                );

                            })
                        }

                    
                    </Row>
                    
                </Container>
            </div>
            )
    }
}