import React from 'react'
import { Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import "./Signup.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Auth} from 'aws-amplify';
import {ForgotPassword} from 'aws-amplify-react'


export default class CustomForgotPassword extends ForgotPassword {

    constructor(props) {
        super(props);
        console.log("Constructor")

        this.state = {
            formType: 'forgotPassword',
            username: null,
            emailError: null,
            passwordError: null,
            loginError: null,
            authCodeError: null,
            confirmPasswordError: null,
            changePasswordError: null,
            forgotPasswordError: null,
            authCode: null,
            formLoadingState: false,

        };
    }

    /**
     * Send auth code for change password
     * 
     * @param {*} event 
     */
    handleSendCode = async (event) => {
        event.preventDefault();

        const email = event.target.elements.formEmail.value;
        var isDataValid = true;

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.length === 0) {
            this.setState({
                emailError: "Please provide an email.",
            });
            isDataValid = false;
        } 
        else if (!emailRegex.test(email)) {
            this.setState({
                emailError: "Please provide a valid email address.",
            });
            isDataValid = false;
        }

        if (!isDataValid) {
            return;
        }

        const username = email;

        this.setState({
            formLoadingState: true,
        })
        try {
            Auth.forgotPassword (username);
            this.setState({
                formLoadingState: false,
            })
        }
        catch(error) {
            console.log("Error signing in", error);
            this.setState({
                forgotPasswordError:"Error during sending code. Please try again.",
                formType: "forgotPassword",
            })
            return;
        }

        this.setState({
            formType: "changePassword",
            emailError: null,
            username: username,
        })
        return;
        
    };

    /**
     * Change user password using auth code
     * 
     * @param {*} event 
     */
    handleChangePassword = async (event) => {

        event.preventDefault();

        const username = this.state.username;
        const authCode = event.target.elements.formAuthCode.value;
        const password = event.target.elements.formPassword.value;
        const confirmPassword = event.target.elements.formConfirmPassword.value;

        var isDataValid = true;

        if (username.length === 0) {
            this.setState({
                changePasswordError: "Error retriving username.",
            });
            isDataValid = false;
        }

        if (password.length === 0) {
            this.setState({
                passwordError: "Please provide a password.",
            });
            isDataValid = false;
        } 
        else if (password.length > 0 && password.length <= 9) {
            this.setState({
                passwordError: "Your password must be at least 10 characters.",
            });
            isDataValid = false;
        }
        else if (password !== confirmPassword) {
            this.setState({
                confirmPasswordError: "Confirm password does not match password.",
            });
            isDataValid = false;
        }

        if (authCode.length === 0) {
            this.setState({
                authCodeError: "Please provide authentication code.",
            });
            isDataValid = false;
        }

        

        if (!isDataValid) {
            return;
        }

        this.setState({
            formLoadingState: true,
        })
        try {
            await Auth.forgotPasswordSubmit (username,authCode, password);
            this.setState( {
                formLoadingState: false,
            });
            this.props.history.push("/login");
        }
        catch(error) {
            console.log("Error changing password", error);
            this.setState( {
                formType: 'changePassword',
            });
            return;
        }
    };

    /**
     * Render UI for Forgot Password page
     */
    render() {
        const formType = this.state.formType
        console.log(formType)

        return (
            <div className="form-container pt-3 h-auto">
                <Container className="form-parent-div">
                    
                    {
                        formType === 'forgotPassword' && (
                            <Form onSubmit={(event) => this.handleSendCode(event)}>
                                <Row>
                                    <Col sm={true} md={true} lg={true} className="p-3 p-md-4 p-lg-5">
                                        <Row className="first-row">
                                            <Col sm={true} md={true} lg={true}>
                                                <div className="d-flex flex-column">
                                                <div className="title-text">
                                                    <p>Young Brilliant Minds</p>
                                                </div>
                                                <div>
                                                    <h5>
                                                    Maximize your learnings through power of community
                                                    </h5>
                                                </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="name-row">
                                            <Col sm={12} md={8} lg={8}>
                                                <Form.Group controlId="formEmail">
                                                    <Form.Control
                                                        className={`login-input ${
                                                            this.state.emailError === null ? "" : "invalid-field"
                                                        }`}
                                                        placeholder="Email"
                                                        onChange={(event) =>
                                                            this.setState({
                                                                username: (event.target.elements)?event.target.elements.formEmail.value:null,
                                                                emailError: null,
                                                            })
                                                            }
                                                    />
                                                    <div className="error-text">
                                                        {this.state.emailError}
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="name-row">
                                            <Col sm={12} md={8} lg={8}>
                                                <div className="error-text">
                                                    {this.state.forgotPasswordError}
                                                </div>
                                            </Col>
                                        </Row>
                                        
                                        <Row>
                                            <Col md={8} lg={6}>
                                                <div>
                                                <Button
                                                    size="lg"
                                                    type="submit"
                                                    block
                                                    className="next-btn"
                                                    disabled={this.state.formLoadingState}
                                                >
                                                    {this.state.formLoadingState && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        variant="light"
                                                        style={{
                                                        marginBottom: 4,
                                                        }}
                                                    />
                                                    )}
                                                    <span
                                                    style={{
                                                        paddingLeft: 5,
                                                    }}
                                                    >
                                                    RESET PASSWORD
                                                    </span>
                                                </Button>
                                                </div>
                                            </Col>
                                            <Col md={8} lg={6}>
                                                <div className="pb-3 pb-md-0 pb-lg-0 pt-4 pt-md-0 pt-ld-0">
                                                <Button
                                                    variant="dark"
                                                    size="lg"
                                                    block
                                                    className="back-btn"
                                                    onClick={this.props.prevSection}
                                                >
                                                    <div className="text-center">
                                                    <img
                                                        alt=""
                                                        src="/arrow.png"
                                                        width="20"
                                                        height="20"
                                                        className="align-middle"
                                                    />
                                                    <span className="align-middle pl-2">GO BACK</span>
                                                    </div>
                                                </Button>
                                                </div>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>

                            </Form>
                        )
                    }

                    {
                        formType === 'changePassword' && (
                            <Form onSubmit={(event) => this.handleChangePassword(event)}>
                                <Row>
                                    <Col sm={true} md={true} lg={true} className="p-3 p-md-4 p-lg-5">
                                        <Row className="first-row">
                                            <Col sm={true} md={true} lg={true}>
                                                <div className="d-flex flex-column">
                                                <div className="title-text">
                                                    <p>Young Brilliant Minds</p>
                                                </div>
                                                <div>
                                                    <h5>
                                                    Maximize your learnings through power of community
                                                    </h5>
                                                </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="name-row">
                                            <Col sm={12} md={8} lg={8}>
                                                <Form.Group controlId="formAuthCode">
                                                    <Form.Control
                                                        className={`login-input ${
                                                        this.state.authCodeError === null
                                                            ? ""
                                                            : "invalid-field"
                                                        }`}
                                                        placeholder="Auth Code"
                                                        onChange={(event) =>
                                                            this.setState({
                                                                authCodeError: null,
                                                            })
                                                        }
                                                    />
                                                    <div className="error-text">
                                                        {this.state.authCodeError}
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="name-row">
                                            <Col sm={12} md={8} lg={8}>
                                                <Form.Group controlId="formPassword">
                                                <Form.Control
                                                    type="password"
                                                    className={`login-input ${
                                                    this.state.passwordError === null
                                                        ? ""
                                                        : "invalid-field"
                                                    }`}
                                                    placeholder="Password (min. 10 Characters)"
                                                    onChange={(event) =>
                                                    this.setState({
                                                        passwordError: null,
                                                    })
                                                    }
                                                />
                                                <div className="error-text">
                                                    {this.state.passwordError}
                                                </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="name-row">
                                            <Col sm={12} md={8} lg={8}>
                                                <Form.Group controlId="formConfirmPassword">
                                                <Form.Control
                                                    type="password"
                                                    className={`login-input ${
                                                    this.state.confirmPasswordError === null
                                                        ? ""
                                                        : "invalid-field"
                                                    }`}
                                                    placeholder="Confirm Password"
                                                    onChange={(event) =>
                                                    this.setState({
                                                        confirmPasswordError: null,
                                                    })
                                                    }
                                                />
                                                <div className="error-text">
                                                    {this.state.confirmPasswordError}
                                                </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="name-row">
                                            <Col sm={12} md={8} lg={8}>
                                                <div className="error-text">
                                                    {this.state.changePasswordError}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8} lg={6}>
                                                <div>
                                                <Button
                                                    size="lg"
                                                    type="submit"
                                                    block
                                                    className="next-btn"
                                                    disabled={this.state.formLoadingState}
                                                >
                                                    {this.state.formLoadingState && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        variant="light"
                                                        style={{
                                                        marginBottom: 4,
                                                        }}
                                                    />
                                                    )}
                                                    <span
                                                    style={{
                                                        paddingLeft: 5,
                                                    }}
                                                    >
                                                    CHANGE PASSWORD
                                                    </span>
                                                </Button>
                                                </div>
                                            </Col>
                                            <Col md={8} lg={6}>
                                                <div className="pb-3 pb-md-0 pb-lg-0 pt-4 pt-md-0 pt-ld-0">
                                                <Button
                                                    variant="dark"
                                                    size="lg"
                                                    block
                                                    className="back-btn"
                                                    onClick={this.props.prevSection}
                                                >
                                                    <div className="text-center">
                                                    <img
                                                        alt=""
                                                        src="/arrow.png"
                                                        width="20"
                                                        height="20"
                                                        className="align-middle"
                                                    />
                                                    <span className="align-middle pl-2">GO BACK</span>
                                                    </div>
                                                </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Form>
                        )
                    }

                </Container>
            </div>

        )
    }

}