/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userId
      coins {
        items {
          id
          coins
          coinDate
          eventName
          createdAt
          updatedAt
        }
        nextToken
      }
      awards {
        items {
          id
          awardName
          awardDescription
          awardDate
          eventName
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          userId
          eventId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      courses {
        items {
          id
          userId
          courseId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      lessons {
        items {
          id
          userId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCoins = /* GraphQL */ `
  query GetCoins($id: ID!) {
    getCoins(id: $id) {
      id
      coins
      coinDate
      eventName
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCoinss = /* GraphQL */ `
  query ListCoinss(
    $filter: ModelCoinsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoinss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        coins
        coinDate
        eventName
        user {
          id
          userId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAwards = /* GraphQL */ `
  query GetAwards($id: ID!) {
    getAwards(id: $id) {
      id
      awardName
      awardDescription
      awardDate
      eventName
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAwardss = /* GraphQL */ `
  query ListAwardss(
    $filter: ModelAwardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAwardss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        awardName
        awardDescription
        awardDate
        eventName
        user {
          id
          userId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      eventId
      eventName
      eventType
      eventDescription
      eventSpeaker
      eventImageUrl
      eventRegistrationLink
      eventJoinLink
      eventStartTime
      eventEndTime
      eventCoins
      display
      users {
        items {
          id
          userId
          eventId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        eventName
        eventType
        eventDescription
        eventSpeaker
        eventImageUrl
        eventRegistrationLink
        eventJoinLink
        eventStartTime
        eventEndTime
        eventCoins
        display
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      courseId
      courseName
      courseType
      courseDescription
      courseImageUrl
      courseLink
      courseCoins
      courseStartDate
      display
      users {
        items {
          id
          userId
          courseId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      lessons {
        items {
          id
          courseId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseId
        courseName
        courseType
        courseDescription
        courseImageUrl
        courseLink
        courseCoins
        courseStartDate
        display
        users {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLesson = /* GraphQL */ `
  query GetLesson($id: ID!) {
    getLesson(id: $id) {
      id
      lessonId
      lessonName
      lessonType
      lessonDescription
      lessonImageUrl
      lessonVideoUrl
      lessonPresenter
      lessonCoins
      lessonStartDate
      display
      users {
        items {
          id
          userId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      courses {
        items {
          id
          courseId
          lessonId
          status
          statusDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLessons = /* GraphQL */ `
  query ListLessons(
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lessonId
        lessonName
        lessonType
        lessonDescription
        lessonImageUrl
        lessonVideoUrl
        lessonPresenter
        lessonCoins
        lessonStartDate
        display
        users {
          nextToken
        }
        courses {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserEvents = /* GraphQL */ `
  query GetUserEvents($id: ID!) {
    getUserEvents(id: $id) {
      id
      userId
      eventId
      status
      statusDate
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      event {
        id
        eventId
        eventName
        eventType
        eventDescription
        eventSpeaker
        eventImageUrl
        eventRegistrationLink
        eventJoinLink
        eventStartTime
        eventEndTime
        eventCoins
        display
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserEventss = /* GraphQL */ `
  query ListUserEventss(
    $filter: ModelUserEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserEventss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        eventId
        status
        statusDate
        user {
          id
          userId
          createdAt
          updatedAt
        }
        event {
          id
          eventId
          eventName
          eventType
          eventDescription
          eventSpeaker
          eventImageUrl
          eventRegistrationLink
          eventJoinLink
          eventStartTime
          eventEndTime
          eventCoins
          display
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserCourses = /* GraphQL */ `
  query GetUserCourses($id: ID!) {
    getUserCourses(id: $id) {
      id
      userId
      courseId
      status
      statusDate
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      course {
        id
        courseId
        courseName
        courseType
        courseDescription
        courseImageUrl
        courseLink
        courseCoins
        courseStartDate
        display
        users {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserCoursess = /* GraphQL */ `
  query ListUserCoursess(
    $filter: ModelUserCoursesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserCoursess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        courseId
        status
        statusDate
        user {
          id
          userId
          createdAt
          updatedAt
        }
        course {
          id
          courseId
          courseName
          courseType
          courseDescription
          courseImageUrl
          courseLink
          courseCoins
          courseStartDate
          display
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserLessons = /* GraphQL */ `
  query GetUserLessons($id: ID!) {
    getUserLessons(id: $id) {
      id
      userId
      lessonId
      status
      statusDate
      user {
        id
        userId
        coins {
          nextToken
        }
        awards {
          nextToken
        }
        events {
          nextToken
        }
        courses {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      lesson {
        id
        lessonId
        lessonName
        lessonType
        lessonDescription
        lessonImageUrl
        lessonVideoUrl
        lessonPresenter
        lessonCoins
        lessonStartDate
        display
        users {
          nextToken
        }
        courses {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserLessonss = /* GraphQL */ `
  query ListUserLessonss(
    $filter: ModelUserLessonsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserLessonss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        lessonId
        status
        statusDate
        user {
          id
          userId
          createdAt
          updatedAt
        }
        lesson {
          id
          lessonId
          lessonName
          lessonType
          lessonDescription
          lessonImageUrl
          lessonVideoUrl
          lessonPresenter
          lessonCoins
          lessonStartDate
          display
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourseLessons = /* GraphQL */ `
  query GetCourseLessons($id: ID!) {
    getCourseLessons(id: $id) {
      id
      courseId
      lessonId
      status
      statusDate
      course {
        id
        courseId
        courseName
        courseType
        courseDescription
        courseImageUrl
        courseLink
        courseCoins
        courseStartDate
        display
        users {
          nextToken
        }
        lessons {
          nextToken
        }
        createdAt
        updatedAt
      }
      lesson {
        id
        lessonId
        lessonName
        lessonType
        lessonDescription
        lessonImageUrl
        lessonVideoUrl
        lessonPresenter
        lessonCoins
        lessonStartDate
        display
        users {
          nextToken
        }
        courses {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCourseLessonss = /* GraphQL */ `
  query ListCourseLessonss(
    $filter: ModelCourseLessonsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseLessonss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseId
        lessonId
        status
        statusDate
        course {
          id
          courseId
          courseName
          courseType
          courseDescription
          courseImageUrl
          courseLink
          courseCoins
          courseStartDate
          display
          createdAt
          updatedAt
        }
        lesson {
          id
          lessonId
          lessonName
          lessonType
          lessonDescription
          lessonImageUrl
          lessonVideoUrl
          lessonPresenter
          lessonCoins
          lessonStartDate
          display
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContactUs = /* GraphQL */ `
  query GetContactUs($id: ID!) {
    getContactUs(id: $id) {
      id
      userId
      name
      email
      message
      note
      createdAt
      updatedAt
    }
  }
`;
export const listContactUss = /* GraphQL */ `
  query ListContactUss(
    $filter: ModelContactUsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactUss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        name
        email
        message
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserAnalytics = /* GraphQL */ `
  query GetUserAnalytics($id: ID!) {
    getUserAnalytics(id: $id) {
      id
      userId
      page
      date
      deviceType
      pageParamName
      pageParamValue
      createdAt
      updatedAt
    }
  }
`;
export const listUserAnalyticss = /* GraphQL */ `
  query ListUserAnalyticss(
    $filter: ModelUserAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAnalyticss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        page
        date
        deviceType
        pageParamName
        pageParamValue
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
