import React, {Component} from 'react'
import { Fade } from 'react-bootstrap';
import '../styles/css/style.scss';
import ContactMenu from './ContactMenu.js';
import Contact from '../public/Contact.js';
import ContactPadding from './ContactPadding.js';
import MetaDecorator from '../lib/MetaDecorator';
import { Auth } from 'aws-amplify';
import TrackPage from '../lib/TrackPage';
//import {PageView} from '../lib/PageTracking.js';

export default class ContactUs extends Component {

    constructor(props) {
        super(props);
        //console.log("Constructor")

        this.state = {
           

        };
    }

    componentDidMount() {
        this.logPage();
      }

    

    /**
     * 
     */
    logPage = async () => {

        try {

            const userName = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            });

            // Track Page
            TrackPage(userName, "About Us", "", "");

        }
        catch (err) {
            console.log(err);
          }
    }

    render() {

        return (
            <div className="justify-content-center">
                <MetaDecorator title="gencode Contact Us" description="Contact us to learn more about the gencode platform and how you can help advance the mission." />
                <ContactMenu />
                <Fade>
                    <Contact />
                </Fade>
                <Fade>
                    <ContactPadding />
                </Fade>
            </div>

            )
    }
}