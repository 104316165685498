import React, {Component} from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ReactPlayer from "react-player/lazy";
import '../styles/css/style.scss';
import {Auth} from "aws-amplify";
import {API,graphqlOperation} from '@aws-amplify/api'
import { listCourseLessonss } from '../graphql/queries'
import { listUserLessonss } from '../../src/graphql/queries'
import { createUserLessons } from '../graphql/mutations'
import { updateUserLessons } from '../graphql/mutations'
import { createCoins } from '../graphql/mutations'


export default class CourseLessons extends Component {

    constructor(props) {
        super(props);

        this.state = {
           courseId: props.id,
           courseLessons: [],
           myLessons: [],
           setHandleOnProgress: [],
        };

    }

    componentDidMount() {
        this.retrieveCourseLessonsData();
      }

    

    /**
     * 
     */
    retrieveCourseLessonsData = async () => {

        try {

            await Auth.currentAuthenticatedUser().then((user) => {
                this.setState({
                    username: user.attributes.email,   
                });
            });
            //console.log(this.state.username);

            //console.log(this.state.courseId);
            await API.graphql(graphqlOperation(listCourseLessonss,  { filter: {courseId: { eq: this.state.courseId }}})).then((response) => {
                    this.setState({
                        courseLessons: response.data.listCourseLessonss.items,
                    })
            });
            //console.log(this.state.courseLessons);

            await API.graphql(graphqlOperation(listUserLessonss, {filter: {userId: {eq: this.state.username}}})).then((response) => {
                    this.setState({
                        myLessons: response.data.listUserLessonss.items,
                    })
            });
            //console.log(this.state.myLessons);

        }
        catch (err) {
            console.log(err);
          }
    }
 


    handleOnStart = async(lessonId) => {
        //event.preventDefault();

        //console.log(this.state.username + " started Playing Video for lesson: "+lessonId);

        const myLessonsLocal = this.state.myLessons.filter((lesson, index) => {
            return (lesson.lessonId === lessonId);
        });
        //console.log(myLessonsLocal);

        var myLesson = myLessonsLocal[0];
        //console.log(myLesson);

        if(myLesson === undefined) {
            //console.log("My Lesson is undefined");
            const userLesson = {
                userId: this.state.username,
                lessonId:  lessonId,
                status: "In Progress",
                statusDate: new Date().toISOString(),
            };

            await API.graphql(graphqlOperation(createUserLessons, {input: userLesson})).then((response) => {
                console.log(response.data.createUserLessons)                   
            });

            await API.graphql(graphqlOperation(listUserLessonss, {filter: {userId: {eq: this.state.username}}})).then((response) => {
                    this.setState({
                        myLessons: response.data.listUserLessonss.items,
                    })
            });
            //console.log(this.state.myLessons);

        }
        else {  
            //console.log("My Lesson is not undefined");
            //console.log(myLesson);
            if(myLesson.status === "In Progress") {
                //console.log("Update");
                const userLesson = {
                    id: myLesson.id,
                    userId: myLesson.userId,
                    lessonId:  myLesson.lessonId,
                    status: myLesson.status,
                    statusDate: new Date().toISOString(),
                };

                myLesson.statusDate = new Date().toISOString();
                //console.log(myLesson)

                await API.graphql(graphqlOperation(updateUserLessons, {input: userLesson})).then((response) => {
                    console.log(response.data.updateUserLessons)                   
                });

                await API.graphql(graphqlOperation(listUserLessonss, {filter: {userId: {eq: this.state.username}}})).then((response) => {
                        this.setState({
                            myLessons: response.data.listUserLessonss.items,
                        })
                });
                //console.log(this.state.myLessons);
            }
        }


        return;
        
    };

    handleOnPause = () => {
        //event.preventDefault();

        //console.log(this.state.username + " paused Playing Video");
        return;
        
    };

    handleOnProgress = async(played, lessonId) => {
        //console.log("Played:"+played+", lessonId:"+lessonId);
        //console.log(this.state.setHandleOnProgress);

        if(played > 0.85) {
            //console.log("Played:"+played+"");
            const myLessonsLocal = this.state.myLessons.filter((lesson, index) => {
                return (lesson.lessonId === lessonId);
            });

            var myLesson = myLessonsLocal[0];

            if(myLesson !== undefined) {
            // console.log("My Lesson is not undefined");
                //console.log(myLesson);
                if(myLesson.status === "In Progress") {
                    console.log("Marking lesson as complete");
                    const userLesson = {
                        id: myLesson.id,
                        userId: myLesson.userId,
                        lessonId:  myLesson.lessonId,
                        status: "Completed",
                        statusDate: new Date().toISOString(),
                    };

                    //myLesson.statusDate = new Date().toISOString();
                    //console.log(myLesson)

                    await API.graphql(graphqlOperation(updateUserLessons, {input: userLesson})).then((response) => {
                        //console.log(response.data.updateUserLessons)                   
                    });
                    
                    //Assign Lesson coins
                    // Add Signup Coins
                    const gqUserCoins = {
                        coins: myLessonsLocal[0].lesson.lessonCoins,
                        coinDate:  new Date().toISOString(),
                        eventName: myLessonsLocal[0].lesson.lessonName,
                        coinsUserId: this.state.username,
                    };

                    await API.graphql(graphqlOperation(createCoins, {input: gqUserCoins})).then((response) => {
                        //console.log(response.data.createCoins)                   
                    });

                    

                    await API.graphql(graphqlOperation(listUserLessonss, {filter: {userId: {eq: this.state.username}}})).then((response) => {
                            this.setState({
                                myLessons: response.data.listUserLessonss.items,
                            })
                    });
                    //console.log(this.state.myLessons);
                }
            }
        }


        return;

    }

    handleOnEnded = async(lessonId) => {
        //event.preventDefault();

        const myLessonsLocal = this.state.myLessons.filter((lesson, index) => {
            return (lesson.lessonId === lessonId);
        });

        var myLesson = myLessonsLocal[0];

        if(myLesson !== undefined) {
           // console.log("My Lesson is not undefined");
            //console.log(myLesson);
            if(myLesson.status === "In Progress") {
                console.log("Marking lesson as Complete");
                const userLesson = {
                    id: myLesson.id,
                    userId: myLesson.userId,
                    lessonId:  myLesson.lessonId,
                    status: "Completed",
                    statusDate: new Date().toISOString(),
                };

                //myLesson.statusDate = new Date().toISOString();
                //console.log(myLesson)

                await API.graphql(graphqlOperation(updateUserLessons, {input: userLesson})).then((response) => {
                    //console.log(response.data.updateUserLessons)                   
                });
                
                //Assign Lesson coins
                // Add Signup Coins
                const gqUserCoins = {
                    coins: myLessonsLocal[0].lesson.lessonCoins,
                    coinDate:  new Date().toISOString(),
                    eventName: myLessonsLocal[0].lesson.lessonName,
                    coinsUserId: this.state.username,
                };

                await API.graphql(graphqlOperation(createCoins, {input: gqUserCoins})).then((response) => {
                    //console.log(response.data.createCoins)                   
                });

                

                await API.graphql(graphqlOperation(listUserLessonss, {filter: {userId: {eq: this.state.username}}})).then((response) => {
                        this.setState({
                            myLessons: response.data.listUserLessonss.items,
                        })
                });
                //console.log(this.state.myLessons);
            }
        }


        return;
        
    };


    render() {

        return (


            this.state.courseLessons.sort((a,b) => (a.lessonId > b.lessonId)? 1 : -1).map((course, index) => {
                var divStyle = "lesson1"
                if(index%2 == 1) {
                    divStyle = "lesson2"
                }

                var lessonStatus = "Not Started"
                var lessonStatusDate = "N/A"
                var lessonPresenter = ""
                this.state.myLessons.map((myLesson, index) => {
                    if(course.lessonId === myLesson.lessonId) {
                        lessonStatus = myLesson.status;
                        lessonStatusDate = myLesson.statusDate;
                    }
                });

                if(course.lesson.display === "True") {
                    return (

                        <div id={divStyle} >
                            <Container>
                                <Row className="about-text">
                                    <Col xs={12} md={6} key={index}>
                                        <div>
                                            <ReactPlayer
                                                url={course.lesson.lessonVideoUrl}
                                                width="520"
                                                controls={true}
                                                onStart={() => this.handleOnStart(course.lessonId)}
                                                onProgress={(progress) => this.handleOnProgress(progress.played, course.lessonId)}
                                                onEnded={() => this.handleOnEnded(course.lessonId)}

                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} key={index+"_lesson"}>
                                        <div >
                                            <h2>{course.lesson.lessonName}</h2>
                                            <p>{course.lesson.lessonDescription}</p>
                                            <p>
                                            <br/>
                                                <b>Presenter: </b>{course.lesson.lessonPresenter}
                                                <br/>
                                                <b>Status: </b>{lessonStatus}
                                                <br/>
                                                <b>Date: </b>{lessonStatusDate}
                                            </p>
    
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    )
                }

                
            })


            
    
            
        )
    }
}