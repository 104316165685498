import React, {useState, useEffect} from 'react'
import './App.css';
import {useHistory } from "react-router-dom";
import { AppContext } from "./lib/ContextLib.js";
import {Auth} from "aws-amplify";
import Routes from "./Routes";
import ReactGA from "react-ga";
import withGAPageTracker from './lib/withGAPageTracker.js';


const App = () => {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const history = useHistory();

  // history.listen((location) => {
  //     console.log("Setting page to: "+location.pathname);
  //     ReactGA.set({ page: location.pathname });
  //     ReactGA.pageview(location.pathname)
  //   }
  // );

  useEffect(() => {
      onLoad();
    }, 
    []
  );

  async function onLoad() {
    try {
       //Auth.currentSession();
      await Auth.currentSession();
      userHasAuthenticated(true);
      
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push("/login");
  }
  
  return (
     
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
            <Routes/>
      </AppContext.Provider>
  );
}

export default App;
