import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import aboutlogo from '../img/aboutme.jpg';
import '../styles/css/style.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class Intro extends Component {

    render() {

        return(
            <div id="about">
                <Container>
                    <Row className="about-text">
                        <Col xs={12} md={6}>
                            <div>
                            <LazyLoadImage
                                alt="Riya Patel"
                                src={aboutlogo}
                                className="img-responsive" />
                                {/* <img src={aboutlogo} className="img-responsive" alt=''/> */}
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div >
                                <h2>About Me</h2>
                                <p>
                                    My name is Riya Patel. I am a junior in Monroe high school in NJ who wants to pursue computer science in the future. I recall first being fascinated by this field when my dad and I built our first computer when I was only 8 years old. But what really piqued my interest was what I learned from a Computer Science course at Harvard Summer School where I was exposed to the real world applications of Computer Science. I was able to write programs that created instagram filters, encrypted ciphertext, and even determined the reading level of the user based on a given paragraph. Taking this course inspired me to share my knowledge with others and expose them to similar topics.
                                </p>
                                <p>
                                    I was fortunate enough to be given the opportunity to learn about computers in middle school and high school, but I know that not everyone is. From my experience, I know that computer science education for elementary, middle, and high school students isn’t always easily accessible. Launching gencode is my attempt to create a platform that provides young students with free education in computer science topics through video lectures, hands-on workshops and interactive events.
                                </p>

                
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

}