import React, {useState} from 'react'
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import "./Signup.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Auth} from 'aws-amplify';
import { useFormFields } from "../lib/HookLib.js";
import MetaDecorator from '../lib/MetaDecorator';
import TrackPage from '../lib/TrackPage';


export default function ForgotPassword() {

    const [fields, handleFieldChange] = useFormFields({
        email: "",
        authCode: "",
        password: "",
        confirmPassword: "",
      });

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [formType, setFormType] = useState('sendCodeForm');
    const [emailError, setEmailError] = useState('');
    const [authCodeError, setAuthCodeError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    
    const [sendCodeError, setSendCodeError] = useState('');
    const [resetPasswordError, setResetPasswordError] = useState('');

    /**
     * Send auth code for change password
     * 
     * @param {*} event 
     */
    async function handleSendCode (event) {
        event.preventDefault();

        var isDataValid = true;

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (fields.email.length === 0) {
            setEmailError("Please provide an email.");
            isDataValid = false;
        } 
        else if (!emailRegex.test(fields.email)) {
            setEmailError("Please provide a valid email address.");
            isDataValid = false;
        }

        if (!isDataValid) {
            return;
        }

        //const username = email;

        setIsLoading(true);

        try {
            await Auth.forgotPassword (fields.email.toString().toLowerCase());
            TrackPage(fields.email.toString().toLowerCase(), "Forgot Password", "Status", "Success - Confirmation Code Sent");
        }
        catch(error) {
            console.log("Error signing in", error);
            setSendCodeError("Error during sending code. Please try again.");
            setFormType('sendCodeForm');
            setIsLoading(false);
            TrackPage(fields.email.toString().toLowerCase(), "Forgot Password", "Status", "Error - Failed Email Validation");
            return;
        }

        setIsLoading(false);
        setFormType('resetPasswordForm');
        return;
        
    };

    /**
     * Change user password using auth code
     * 
     * @param {*} event 
     */
    function handleChangePassword (event) {

        event.preventDefault();

        var isDataValid = true;

        if (fields.email.length === 0) {
            setResetPasswordError("Error retriving username.");
            isDataValid = false;
        }

        if (fields.password.length === 0) {
            setPasswordError("Please provide a password.");
            isDataValid = false;
        } 
        else if (fields.password.length > 0 && fields.password.length <= 9) {
            setPasswordError("Your password must be at least 10 characters.");
            isDataValid = false;
        }
        else if (fields.password !== fields.confirmPassword) {
            setPasswordError("Confirm password does not match password.");
            isDataValid = false;
        }

        if (fields.authCode.length === 0) {
            setAuthCodeError("Please provide authentication code.");
            isDataValid = false;
        }

        

        if (!isDataValid) {
            return;
        }

        setIsLoading(true);

        try {
            Auth.forgotPasswordSubmit (fields.email.toString().toLowerCase(),fields.authCode, fields.password);

            // Analytics.record({
            //     name: "Password",
            //     attributes: {
            //         action: "Successful Change Password",
            //         id: fields.email,
            //     }
            // });

            setIsLoading(false);

             //Track Page
             TrackPage(fields.email.toString().toLowerCase(), "Forgot Password", "Status", "Success - Password Changed");

            history.push("/login?redirect=/courses");
        }
        catch(error) {
            console.log("Error changing password", error);

            // Analytics.record({
            //     name: "Password",
            //     attributes: {
            //         action: "Unsuccessful Change Password",
            //         id: fields.email,
            //     }
            // });

            setSendCodeError("Error during sending code. Please try again.");
            setFormType('resetPasswordForm');
            setIsLoading(false);

            //Track Page
            TrackPage(fields.email.toString().toLowerCase(), "Forgot Password", "Status", "Error - Failed Password Change");
            return;
        }
    };

    

    function renderSendCodeForm() {
        return (
            <div className="form-container2 pt-3 h-auto">
                <MetaDecorator title="gen{code} Forgot Password" description="" />
                <Container className="form-parent-div">
                    <Form onSubmit={(event) => handleSendCode(event)}>
                        <Row>
                            <Col sm={true} md={true} lg={true} className="p-3 p-md-4 p-lg-5">
                                <Row className="first-row">
                                    <Col sm={true} md={true} lg={true}>
                                        <div className="d-flex flex-column">
                                        <div >
                                            <Row>
                                                <Col>
                                                    <p><img alt="" src="/logo_gencode_blue.png" width="200" height="40" className="d-inline-block align-top"/></p>
                                                </Col>
                                                <Col className="text-right">
                                                    <a href="/" className="btn btn-lg btn-custom page-scroll">Home</a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row className="name-row">
                                                <Col>

                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <h4>
                                                Inspiring the next generation of coders
                                            </h4>
                                        </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <Form.Group controlId="email">
                                            <Form.Control
                                                className={`login-input ${
                                                    emailError === '' ? "login-input" : "invalid-field"
                                                }`}
                                                placeholder="Email"
                                                value={fields.email}
                                                onChange={e => {
                                                        handleFieldChange(e);
                                                        setEmailError('');
                                                    }
                                                }
                                            />
                                            <div className="error-text">
                                                {emailError}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <div className="error-text">
                                            {sendCodeError}
                                        </div>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col>
                                        <div>
                                        <Button
                                            size="lg"
                                            type="submit"
                                            block
                                            className="next-btn"
                                            disabled={isLoading}
                                        >
                                            {isLoading && (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="light"
                                                style={{
                                                marginBottom: 4,
                                                }}
                                            />
                                            )}
                                            <span
                                            style={{
                                                paddingLeft: 0,
                                            }}
                                            >
                                            RESET PASSWORD
                                            </span>
                                        </Button>
                                        </div>
                                    </Col>
                                    {/* <Col>
                                        <div className="pb-3 pb-md-0 pb-lg-0 pt-4 pt-md-0 pt-ld-0">
                                        <Button
                                            variant="dark"
                                            size="lg"
                                            block
                                            className="back-btn"
                                        >
                                            <div className="text-center">
                                            <img
                                                alt=""
                                                src="/arrow.png"
                                                width="20"
                                                height="20"
                                                className="align-middle"
                                            />
                                            <span className="align-middle pl-2">GO BACK</span>
                                            </div>
                                        </Button>
                                        </div>
                                    </Col>*/}
                                </Row> 
                                <Row className="name-row">
                                </Row>
                                <Row className="name-row">
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>

        )
    }

    function renderResetPasswordForm() {
        return (
            <div className="form-container pt-3 h-auto">
                <Container className="form-parent-div">
                    <Form onSubmit={(event) => handleChangePassword(event)}>
                        <Row>
                            <Col sm={true} md={true} lg={true} className="p-3 p-md-4 p-lg-4">
                                <Row className="first-row">
                                    <Col sm={true} md={true} lg={true}>
                                        <div className="d-flex flex-column">
                                        <div >
                                            <Row>
                                                <Col>
                                                    <p><img alt="" src="/logo_gencode_blue.png" width="200" height="40" className="d-inline-block align-top"/></p>
                                                </Col>
                                                <Col className="text-right">
                                                    <a href="/" className="btn btn-lg btn-custom page-scroll">Home</a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row className="name-row">
                                                <Col>

                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <h4>
                                                Inspring the next generation of coders
                                            </h4>
                                        </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <Form.Group controlId="authCode">
                                            <Form.Control
                                                className={`login-input ${
                                                    authCodeError === ''
                                                    ? ""
                                                    : "invalid-field"
                                                }`}
                                                placeholder="Auth Code"
                                                value={fields.authCode}
                                                onChange={e => {
                                                        handleFieldChange(e);
                                                        setAuthCodeError('');
                                                    }
                                                }
                                            />
                                            <div className="error-text">
                                                {authCodeError}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <Form.Group controlId="password">
                                        <Form.Control
                                            type="password"
                                            className={`login-input ${
                                                passwordError === ''
                                                ? ""
                                                : "invalid-field"
                                            }`}
                                            placeholder="Password (min. 10 Characters)"
                                            value={fields.password}
                                            onChange={e => {
                                                    handleFieldChange(e);
                                                    setPasswordError('');
                                                }
                                            }
                                        />
                                        <div className="error-text">
                                            {passwordError}
                                        </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col>
                                        <Form.Group controlId="confirmPassword">
                                        <Form.Control
                                            type="password"
                                            className={`login-input ${
                                                confirmPasswordError === ''
                                                ? ""
                                                : "invalid-field"
                                            }`}
                                            placeholder="Confirm Password"
                                            value={fields.confirmPassword}
                                            onChange={e => {
                                                    handleFieldChange(e);
                                                    setConfirmPasswordError('');
                                                }
                                            }
                                        />
                                        <div className="error-text">
                                            {confirmPasswordError}
                                        </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="name-row">
                                    <Col sm={12} md={8} lg={8}>
                                        <div className="error-text">
                                            {resetPasswordError}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div>
                                        <Button
                                            size="lg"
                                            type="submit"
                                            block
                                            className="next-btn"
                                            disabled={isLoading}
                                        >
                                            {isLoading && (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="light"
                                                style={{
                                                marginBottom: 4,
                                                }}
                                            />
                                            )}
                                            <span
                                            style={{
                                                paddingLeft: 5,
                                            }}
                                            >
                                            CHANGE PASSWORD
                                            </span>
                                        </Button>
                                        </div>
                                    </Col>
                                    
                                </Row>
                            </Col>
                        </Row>

                    </Form>

                </Container>
            </div>

        )
    }

    /**
     * Render UI for Forgot Password page
     */
    return (
        <div>
          {formType === 'resetPasswordForm' ? renderResetPasswordForm() : renderSendCodeForm()}
        </div>
      );

}