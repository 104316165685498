exports.grade_list = [
  "First Grade",
  "Second Grade",
  "Third Grade",
  "Fourth Grade",
  "Fifth Grade",
  "Sixth Grade",
  "Seventh Grade",
  "Eighth Grade",
  "Ninth Grade",
  "Tenth Grade",
  "Eleventh Grade",
  "Twelth Grade",
  "College",
  "Other",
];
