import React, {Component} from 'react';

export default class Intro extends Component {

    render() {

        return(
               <header id="header">
                    <div className="intro">
                        <div className="overlay">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 col-md-offset-2 intro-text">
                                        <h1>gen{'{'}<font color="#84A3BC">code</font>{'}'}<span></span></h1>
                                        <p><font color="#243665">Inspiring the next generation of coders through practical hands-on education in computer programming.</font></p>
                                        {/* <a href="#features" className="btn btn-lg btn-custom page-scroll">Learn More</a>  */}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
        )
    }

}